// Chakra imports
import { Box, Grid, Skeleton, Stack } from '@chakra-ui/react';

// Custom components
import CV from 'views/student/profile/components/CV';
import ProfileDetails from 'views/student/profile/components/ProfileDetails';

// API
import studentApi from 'core/api/student.api';

// Models
import { useQuery } from 'react-query';

export default function Profile() {
    const { data: student, isLoading } = useQuery('student-profile', studentApi.getStudent);

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            {/* Main Fields */}
            {!student || isLoading ? (
                <Stack>
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                </Stack>
            ) : (
                <Grid
                    templateColumns={{
                        base: '1fr',
                        lg: 'repeat(2, 1fr)',
                    }}
                    templateRows={{
                        base: 'repeat(2, 1fr)',
                        lg: '1fr',
                    }}
                    gap={{ base: '20px', xl: '20px' }}
                >
                    <ProfileDetails student={student} />
                    <CV
                        minH={{ base: 'auto', lg: '420px', '2xl': '100vh' }}
                        pe="20px"
                        pb={{ base: '100px', lg: '20px' }}
                        cvList={student.cvList}
                        isLoading={isLoading}
                    />
                </Grid>
            )}
        </Box>
    );
}
