// Chakra imports
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Flex, Icon, IconButton, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect } from 'react';
// Assets
import { useDropzone } from 'react-dropzone';
import { MdFilePresent } from 'react-icons/md';

interface DropzoneProps {
    content: JSX.Element | string;
    [x: string]: any;
    onSave?: (file: File) => Promise<void>;
    accept?: string[];
    onFilesChange?: (file: File) => void;
    isEditMode?: boolean;
}

function Dropzone({
    content,
    onFilesChange,
    onSave,
    onChange,
    isEditMode,
    accept = ['.png', '.jpg', '.jpeg'],
    ...rest
}: DropzoneProps) {
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: accept,
        maxFiles: 1,
    });
    const bg = useColorModeValue('gray.100', 'navy.700');
    const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            onFilesChange && onFilesChange(acceptedFiles[0]);
        }
    }, [acceptedFiles, onFilesChange]);

    const files = acceptedFiles.map((file) => (
        <Stack key={file.name}>
            <Icon as={MdFilePresent} color="secondaryGray.700" mr="5px" />
            <Text fontSize="md" key={`${file.name}${file.size}`} color="secondaryGray.700">
                {file.name}
            </Text>
        </Stack>
    ));

    return (
        <Flex direction="column" gap="2" height={'100px'}>
            <Flex
                direction="column"
                align="center"
                justify="center"
                bg={bg}
                border="1px dashed"
                borderColor={borderColor}
                borderRadius="16px"
                w="100%"
                h="max-content"
                minH="100%"
                cursor="pointer"
                {...getRootProps({ className: 'dropzone' })}
                {...rest}
            >
                <input {...getInputProps()} />
                {acceptedFiles.length > 0 ? null : (
                    <Button variant="no-effects" height="100%" py="10px">
                        {content}
                    </Button>
                )}
                {files.length > 0 && (
                    <Box display="flex" px="5px" py="10px">
                        {files}
                    </Box>
                )}
            </Flex>
            {onSave && files.length > 0 && (
                <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2}>
                    <IconButton
                        aria-label="save"
                        icon={<CheckIcon />}
                        onClick={() => onSave && onSave(acceptedFiles[0])}
                    />
                </ButtonGroup>
            )}
        </Flex>
    );
}

export default Dropzone;
