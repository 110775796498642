import axios from 'axios';
import { baseUrl } from 'constant/baseUrl';
import { CompanyJobSummary } from '../models/company-job-summary.model';
import { CompanySummary, CompanySummaryByCategory } from '../models/company-summary.model';
import {
    Company,
    CompanyCreateRequest,
    CompanyFilters,
    CompanyJobsMultipleResponse,
    CompanyNameFilter,
    CompanyUpdateRequest,
    CompanyWithUser,
} from '../models/company.model';
import { IResponse } from '../models/response.model';
import { SponsorshipModel } from '../models/sponsorship.model';

export class CompanyApi {
    getCompany(companyId: string) {
        return axios.get<Company>(`${baseUrl}/companies/${companyId}`);
    }

    getCompanyForStudent(companyId: string) {
        return axios.get(`${baseUrl}/companies/${companyId}/student`);
    }

    getCompanyList() {
        return axios.get(`${baseUrl}/companies`);

        // let res = { data: companyList }
        // return new Promise<any>((resolve, reject) => resolve(res));
    }

    getVacancy(vacancyId: string, userType: string) {
        return axios.get(`${baseUrl}/vacancy/${vacancyId}/${userType}`);
    }

    getSummary() {
        const summary: CompanySummary = {
            total_profiles: 254,
            incomplete_profiles: 213,
            complete_profiles: 41,
            cvs_released: 150,
            vacancies_filled: 207,
        };

        return new Promise<CompanySummary>((resolve, reject) => resolve(summary));
    }

    async getSummaryForList() {
        const response = await axios.get(`${baseUrl}/dashboard/company`);
        return response.data as CompanySummaryByCategory;
    }

    async getCompanies(filters: CompanyFilters, page: number, rowsPerPage: number) {
        const response = await axios.get(`${baseUrl}/companies/filter`, { params: { ...filters, page, rowsPerPage } });
        return response.data as IResponse<Company>;
    }

    async getCompaniesAndJobs(filters: CompanyNameFilter, page: number, rowsPerPage: number) {
        let companyJobSummary: CompanyJobSummary[] = [];

        await axios.get(`${baseUrl}/dashboard/vacancy`).then((res) => (companyJobSummary = res.data));

        const companiesResponse: CompanyJobsMultipleResponse = {
            data: companyJobSummary.slice(rowsPerPage * page, rowsPerPage * (page + 1)),
            page: page,
            rowsPerPage: rowsPerPage,
            count: companyJobSummary.length,
        };

        return new Promise<CompanyJobsMultipleResponse>((resolve, reject) => resolve(companiesResponse));
    }

    async createCompanyWithUserAccount(payload: CompanyCreateRequest) {
        let companyResponse: CompanyCreateRequest;
        await axios.post(`${baseUrl}/companies`, payload).then((res) => (companyResponse = res.data));

        return new Promise<CompanyCreateRequest>((resolve, reject) => resolve(companyResponse));
    }

    async updateCompany(companyId: string, payload: Partial<CompanyUpdateRequest>) {
        let companyResponse: CompanyWithUser;
        await axios
            .patch<CompanyWithUser>(`${baseUrl}/companies/admin/${companyId}`, payload)
            .then((res) => (companyResponse = res.data));

        return new Promise<CompanyWithUser>((resolve, reject) => resolve(companyResponse));
    }

    async updateCvVisibility(id: string, cvVisible: boolean) {
        console.log(cvVisible);
        const response = await axios.patch(
            `${baseUrl}/companies/${id}`,
            {
                cvVisible: cvVisible,
            },
            {
                params: {
                    id,
                    cvVisible,
                },
            }
        );
        return response.data as IResponse<Company>;
    }

    async updateVacancyStatus(id: string, vacancyUpdatable: boolean) {
        const response = await axios.patch(
            `${baseUrl}/companies/${id}`,
            {
                vacancyUpdatable: vacancyUpdatable,
            },
            {
                params: {
                    id,
                    vacancyUpdatable,
                },
            }
        );
        return response.data as IResponse<Company>;
    }

    async updateCvSubmitStatus(id: string, cvSubmitable: boolean) {
        const response = await axios.patch(
            `${baseUrl}/companies/${id}`,
            {
                cvSubmitable: cvSubmitable,
            },
            {
                params: {
                    id,
                    cvSubmitable,
                },
            }
        );
        return response.data as IResponse<Company>;
    }

    async updatePublicStatus(id: string, isPublic: boolean) {
        const response = await axios.patch(
            `${baseUrl}/companies/${id}`,
            {
                isPublic: isPublic,
            },
            {
                params: {
                    id,
                    isPublic,
                },
            }
        );
        return response.data as IResponse<Company>;
    }

    async updatePaymentStatus(id: string, isPaid: boolean) {
        const response = await axios.patch(
            `${baseUrl}/companies/${id}`,
            {
                isPaid: isPaid,
            },
            {
                params: {
                    id,
                    isPaid,
                },
            }
        );
        return response.data as IResponse<Company>;
    }

    async updateSponsorship(id: string, sponsorship: SponsorshipModel) {
        const response = await axios.patch(
            `${baseUrl}/companies/${id}/assign-sponsorship`,
            {
                sponsorship: sponsorship,
            },
            {
                params: {
                    id,
                    sponsorship,
                },
            }
        );
        return response.data as IResponse<Company>;
    }
}
const companyApiInstance = new CompanyApi();

export default companyApiInstance;
