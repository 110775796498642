import {
    Box,
    Button,
    Flex,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

import profileApi from 'core/api/profile.api';

import { MdFilePresent, MdUpload } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useState } from 'react';

export default function FileUploadModal(props: {
    isOpen: boolean;
    isLoading: boolean;
    btnRef: any;
    onClose: () => void;
}) {
    const [isUploading, setIsUploading] = useState(false);
    const { isOpen, onClose, isLoading } = props;
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: ['.pdf'],
        maxFiles: 1,
    });
    const toast = useToast();
    const bg = useColorModeValue('gray.100', 'navy.700');
    const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
    const files = acceptedFiles.map((file) => (
        <Flex alignItems="center">
            <Icon as={MdFilePresent} mr="5px" />
            <Text fontSize="sm" key={`${file.name}${file.size}`}>
                {file.name}
            </Text>
        </Flex>
    ));
    const queryClient = useQueryClient();

    const uploadCV = async () => {
        setIsUploading(true);
        try {
            if (acceptedFiles.length > 0) {
                var payload = new FormData();
                payload.append('file', acceptedFiles[0]);
                await profileApi.uploadCV(payload);
                await queryClient.refetchQueries(['student-profile']);
                toast({
                    title: 'Resume Uploaded!',
                    description: 'Your resume was uploaded successfully',
                    status: 'success',
                    variant: 'solid',
                    duration: 9000,
                    isClosable: true,
                });
            }
            onClose();
        } catch (error) {
            toast({
                title: 'Upload Failed',
                description: "We couldn't upload the file. Please try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setIsUploading(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload CV</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontSize="sm" mb={6} mx={4}>
                        <ul>
                            <li>Only PDFs are allowed</li>
                            <li>
                                Locally rename the file to the name you want to display <br />
                                <i>eg: Luke_Skywalker_DevOps.pdf</i>
                            </li>
                            <li>File name will be seen by the organizations.</li>
                        </ul>
                    </Text>
                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        bg={bg}
                        border="1px dashed"
                        borderColor={borderColor}
                        borderRadius="16px"
                        w="100%"
                        h="max-content"
                        minH="100%"
                        cursor="pointer"
                        {...getRootProps({ className: 'dropzone' })}
                    >
                        <input {...getInputProps()} />
                        <Button variant="no-effects" height="100%" py="10px">
                            <Box>
                                <Icon as={MdUpload} w="40px" h="40px" color="secondaryGray.700" />
                                <Flex justify="center" mx="auto" mb=" ">
                                    <Text fontSize="lg" fontWeight="700" color="secondaryGray.700">
                                        Upload file or Drop a file here
                                    </Text>
                                </Flex>
                            </Box>
                        </Button>
                        {files.length > 0 && (
                            <Flex display="column" px="5px" py="10px">
                                {files}
                            </Flex>
                        )}
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button variant="action" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="brand" onClick={uploadCV} isLoading={isLoading || isUploading}>
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
