import { useContext } from 'react';
// Chakra imports
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Divider,
    Editable,
    EditableInput,
    EditablePreview,
    EditableTextarea,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
    Text,
    Textarea,
    Tooltip,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import EditableControls from 'components/editableControls/EditableControls';
import ProfilePicture from './ProfilePicture';

import profileApi from 'core/api/profile.api';

import { AuthContext } from 'contexts/AuthContext';
import { GetStudentProfileRequest } from 'core/models/student.model';
import { StudentUpdate } from 'core/models/user.model';
import { useQueryClient } from 'react-query';

import './profileDetails.css';

export default function ProfileDetails(props: { student: GetStudentProfileRequest; [x: string]: any }) {
    const { student, ...rest } = props;
    const auth = useContext(AuthContext);
    const toast = useToast();
    const studentRequest: StudentUpdate = { studentId: auth.user.studentId, userId: student.studentId.userId };
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const queryClient = useQueryClient();

    const onSubmit = async (data: StudentUpdate) => {
        try {
            await profileApi.updateUserProfile(data);
            auth.update();
            await queryClient.refetchQueries(['student-profile']);
            toast({
                title: 'Details Updated!',
                description: 'Your details were updated successfully',
                status: 'success',
                variant: 'solid',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error(error);
            toast({
                title: 'Update Failed',
                description: "We couldn't update the details. Please try again",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Card mb={{ base: '0px', lg: '20px' }} height="fit-content" {...rest}>
            <ProfilePicture
                image={
                    student.profilePic.length > 0
                        ? student.profilePic
                        : `https://placehold.co/400x400?text=Add Profile Picture`
                }
                name={student.studentId.name}
                mx="auto"
                mt="-40px"
                size="2xl"
                refetchData={() => queryClient.refetchQueries(['student-profile'])}
            />
            <FormControl mt="20px">
                <Editable
                    defaultValue={
                        student.studentId.name.trim().length > 0 ? student.studentId.name : 'Click to Add Name'
                    }
                    isPreviewFocusable={true}
                    selectAllOnFocus={false}
                    submitOnBlur={false}
                    textAlign="center"
                    onSubmit={(value) => onSubmit({ ...studentRequest, name: value })}
                >
                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                        <Text
                            as={EditablePreview}
                            color={textColorPrimary}
                            fontWeight="bold"
                            fontSize="xl"
                            textAlign="center"
                            mt="5px"
                        />
                    </Tooltip>
                    <Textarea as={EditableInput} />
                    <EditableControls />
                </Editable>
            </FormControl>

            <Divider pb="20px" />
            <FormControl mt="20px">
                <FormLabel fontWeight="500" color={textColorSecondary} fontSize="sm">
                    Your Bio
                </FormLabel>
                <Editable
                    defaultValue={student.description.trim().length > 0 ? student.description : 'Click to Add Bio'}
                    isPreviewFocusable={true}
                    selectAllOnFocus={false}
                    submitOnBlur={false}
                    onSubmit={(value) => onSubmit({ ...studentRequest, description: value })}
                >
                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                        <Text
                            as={EditablePreview}
                            color={textColorPrimary}
                            fontWeight="500"
                            fontSize="md"
                            me="26px"
                            mb="40px"
                        />
                    </Tooltip>
                    <Textarea py={2} px={4} as={EditableTextarea} />
                    <EditableControls />
                </Editable>
            </FormControl>
            <SimpleGrid columns={1} gap="20px">
                <FormControl>
                    <FormLabel fontWeight="500" color={textColorSecondary} fontSize="sm">
                        LinkedIn
                    </FormLabel>
                    <Editable
                        defaultValue={student.linkedIn.trim().length > 0 ? student.linkedIn : 'Click to Add LinkedIn'}
                        onSubmit={(value) => onSubmit({ ...studentRequest, linkedIn: value })}
                        display="flex"
                        flexDirection="column"
                        className="editable"
                    >
                        <Tooltip label="Click to edit" shouldWrapChildren={true}>
                            <EditablePreview color={textColorPrimary} fontWeight="500" fontSize="md" />
                        </Tooltip>
                        <Input py={2} px={4} as={EditableInput} />
                        <EditableControls />
                    </Editable>
                </FormControl>
                <FormControl>
                    <FormLabel fontWeight="500" color={textColorSecondary} fontSize="sm">
                        Personal Site
                    </FormLabel>
                    <Editable
                        defaultValue={
                            student.personalSite.trim().length > 0 ? student.personalSite : 'Click to Add Personal Site'
                        }
                        onSubmit={(value) => onSubmit({ ...studentRequest, personalSite: value })}
                        display="flex"
                        flexDirection="column"
                        className="editable"
                    >
                        <Tooltip label="Click to edit" shouldWrapChildren={true}>
                            <EditablePreview
                                color={textColorPrimary}
                                whiteSpace="pre-wrap"
                                fontWeight="500"
                                fontSize="md"
                            />
                        </Tooltip>
                        <Input py={2} px={4} as={EditableInput} />
                        <EditableControls />
                    </Editable>
                </FormControl>
            </SimpleGrid>
            <Alert mt="20px" status="info" borderRadius="10px">
                <AlertIcon />
                <AlertDescription>Please click on the text to edit the field</AlertDescription>
            </Alert>
        </Card>
    );
}
