// #e2f4ff

export const linkStyles = {
    components: {
        Alert: {
            variants: {
                'left-accent': (props: any) =>
                    props.status === 'info'
                        ? {
                              container: {
                                  bg: '#e2f4ff',
                              },
                          }
                        : undefined,
                subtle: (props: any) =>
                    props.status === 'info'
                        ? {
                              container: {
                                  bg: '#e2f4ff',
                              },
                          }
                        : undefined,
            },
        },
        Link: {
            baseStyle: {
                textDecoration: 'none',
                boxShadow: 'none',
                _focus: {
                    boxShadow: 'none',
                },
                _active: {
                    boxShadow: 'none',
                },
                _hover: {
                    textDecoration: 'none',
                    border: 'none',
                },
            },
            _hover: {
                textDecoration: 'none',
                border: 'none',
            },
        },
    },
};
