import axios from 'axios';
import { baseUrl } from 'constant/baseUrl';

export class StallApi {
    getStalls() {
        return axios.get(`${baseUrl}/stalls`);
    }

    getCompanyStallList() {
        return axios.get(`${baseUrl}/stalls/company`);
    }

    async updateStallAllocation(id: string, status: string) {
        const response = await axios.patch(`${baseUrl}/stalls/${status}`, {
            companyId: id,
        });
        return response.data;
    }
}

const stallApi = new StallApi();
export default stallApi;
