export const globalStyles = {
    colors: {
        brand: {
            50: '#ffe3e5',
            100: '#ffb5b8',
            200: '#fa8687',
            300: '#f65b56',
            400: '#f23427',
            500: '#d80d0d',
            600: '#a90914',
            700: '#790516',
            800: '#4b0010',
            900: '#20000b',
        },
        brandScheme: {
            50: '#ffe3e5',
            100: '#ffb5b8',
            200: '#fa8687',
            300: '#f65b56',
            400: '#f23427',
            500: '#d80d0d',
            600: '#a90914',
            700: '#790516',
            800: '#4b0010',
            900: '#20000b',
        },
        brandTabs: {
            50: '#ffe3e5',
            100: '#ffb5b8',
            200: '#fa8687',
            300: '#f65b56',
            400: '#f23427',
            500: '#d80d0d',
            600: '#a90914',
            700: '#790516',
            800: '#4b0010',
            900: '#20000b',
        },
        secondaryGray: {
            100: '#E0E5F2',
            200: '#E1E9F8',
            300: '#F4F7FE',
            400: '#E9EDF7',
            500: '#8F9BBA',
            600: '#A3AED0',
            700: '#707EAE',
            800: '#707EAE',
            900: '#1B2559',
        },
        red: {
            50: '#FFF5F5',
            100: '#FEEFEE',
            500: '#EE5D50',
            600: '#E31A1A',
        },
        blue: {
            50: '#EFF4FB',
            500: '#3965FF',
        },
        orange: {
            100: '#FFF6DA',
            500: '#FFB547',
        },
        green: {
            100: '#E6FAF5',
            500: '#01B574',
        },
        navy: {
            50: '#d0dcfb',
            100: '#aac0fe',
            200: '#a3b9f8',
            300: '#728fea',
            400: '#3652ba',
            500: '#1b3bbb',
            600: '#24388a',
            700: '#1B254B',
            800: '#111c44',
            900: '#0b1437',
        },
        gray: {
            100: '#FAFCFE',
        },
    },
    styles: {
        global: (props: any) => ({
            body: {
                overflowX: 'hidden',
                // bg: mode('red.50', 'navy.900')(props),
                fontFamily: 'Inter',
                backgroundImage: 'radial-gradient(rgba(255, 29, 0, 0.08) 1px, #fff 0)',
                backgroundSize: '10px 10px',
                // letterSpacing: '-0.5px',
            },
            input: {
                color: 'gray.700',
            },
            html: {
                fontFamily: 'Inter',
            },
        }),
    },
};
