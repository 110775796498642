import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Progress,
    Select,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import studentApi from 'core/api/student.api';
import { StudentFilters } from 'core/models/student.model';
import { Student } from 'core/models/user.model';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
export interface AdminStallManagementProps {}
export interface AdminCandidateManagementProps {}

const initialFilters: StudentFilters = {
    name: '',
    email: '',
    regNo: '',
    indexNo: '',
    degree: '',
    level: '',
    available: '',
};

export default function Candidates() {
    const [stats, setStats] = useState([
        {
            label: 'Total Profiles',
            value: 0,
        },
        {
            label: 'Incomplete Profiles',
            value: 0,
        },
        {
            label: 'Complete Profiles',
            value: 0,
        },
        {
            label: 'CVs Released',
            value: 0,
        },
        {
            label: 'Vacancies Filled',
            value: 0,
        },
    ]);

    const [loading, setLoading] = useState(true);

    const [count, setCount] = useState(10);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [students, setStudents] = useState<Student[] | any[]>([]);

    const [filterFormValues, setFilterFormValues] = useState({ ...initialFilters });
    const [activeFilterValues, setActiveFilterValues] = useState({ ...initialFilters });

    const [shouldRefresh, setShouldRefresh] = useState(false);

    const handleFilterFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilterFormValues({
            ...filterFormValues,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        setLoading(true);

        studentApi.getSummary().then((res) => {
            setStats([
                {
                    label: 'Pending Candidates',
                    value: res.pending_count,
                },
                {
                    label: 'Selected Candidates',
                    value: res.selected_count,
                },
                {
                    label: 'Total Candidates',
                    value: res.total_count,
                },
            ]);
        });

        studentApi.getStudents(activeFilterValues, page, rowsPerPage).then((res) => {
            setStudents(res.data);
            setCount(res.count);
            setPage(res.page);
            setRowsPerPage(res.rowsPerPage);
            setLoading(false);
        });
    }, [activeFilterValues, page, rowsPerPage, shouldRefresh]);

    const changeAvailability = (id: string, name: string, availability: boolean) => {
        Swal.fire({
            title: `Are you sure you want to change the status of ${name} to ${availability ? 'Not ' : ''} Available?`,
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f12b1e',
            cancelButtonColor: '#656565',
            confirmButtonText: 'Yes, change it!',
        }).then((result) => {
            if (result.isConfirmed) {
                studentApi.changeAvailability(id, !availability).then((res) => {
                    setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                    Swal.fire('Updated!', 'Candidate has been updated.', 'success');
                });
            }
        });
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid gap={6} mb={6} gridTemplateColumns="1fr 1fr 1fr 1fr">
                {stats.map((stat) => (
                    <Card key={stat.label} py={3}>
                        <Stat>
                            <StatLabel>{stat.label}</StatLabel>
                            <StatNumber>{stat.value}</StatNumber>
                        </Stat>
                    </Card>
                ))}
            </Grid>

            <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <Grid gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'} mx={6} mb={6} placeItems="end" gap={3}>
                    <FormControl size="sm">
                        <FormLabel id="name">Name</FormLabel>
                        <Input id="name" name="name" size="sm" onChange={handleFilterFormChange} />
                    </FormControl>
                    <FormControl size="small">
                        <FormLabel id="stall-status-label"> Email address </FormLabel>
                        <Input id="email" name="email" size="sm" onChange={handleFilterFormChange} />
                    </FormControl>
                    <FormControl size="small">
                        <FormLabel id="stall-status-label"> Registration number </FormLabel>
                        <Input id="regNo" name="regNo" size="sm" onChange={handleFilterFormChange} />
                    </FormControl>
                    <FormControl size="small">
                        <FormLabel id="stall-status-label"> Index number </FormLabel>
                        <Input id="indexNo" name="indexNo" size="sm" onChange={handleFilterFormChange} />
                    </FormControl>
                    <FormControl size="small">
                        <FormLabel id="degree-label">Degree</FormLabel>
                        <Select
                            id="degree"
                            name="degree"
                            size="sm"
                            value={filterFormValues.degree}
                            onChange={(event) =>
                                setFilterFormValues({
                                    ...filterFormValues,
                                    [event.target.name || 'name_missing']: event.target.value,
                                })
                            }
                        >
                            <option value={'IS3'}>BSc. Information Systems</option>
                            <option value={'IS4'}>BSc. (Hons) Information Systems</option>
                            <option value={'CS3'}>Bsc. Computer Science</option>
                            <option value={'CS4'}>Bsc. (Hons) Computer Science</option>
                            <option value={'SE'}>Bsc. (Hons) Software Engineering</option>
                        </Select>
                    </FormControl>

                    <FormControl size="small">
                        <FormLabel id="level-label">Level</FormLabel>
                        <Select
                            id="level"
                            name="level"
                            size="sm"
                            value={filterFormValues.level}
                            onChange={(event) =>
                                setFilterFormValues({
                                    ...filterFormValues,
                                    [event.target.name || 'name_missing']: event.target.value,
                                })
                            }
                        >
                            <option value={'3'}>Third year</option>
                            <option value={'4'}>Fourth year</option>
                            <option value={'5'}>Other</option>
                        </Select>
                    </FormControl>

                    <FormControl size="small" variant="outlined">
                        <FormLabel id="available-label">Available</FormLabel>
                        <Select
                            id="available"
                            name="available"
                            size="sm"
                            value={filterFormValues.available}
                            onChange={(event) =>
                                setFilterFormValues({
                                    ...filterFormValues,
                                    [event.target.name || 'name_missing']: event.target.value,
                                })
                            }
                        >
                            <option value={'true'}>Yes</option>
                            <option value={'false'}>No</option>
                        </Select>
                    </FormControl>
                    <div></div>
                    <Box gridColumn={'span 2'}>
                        <Button
                            variant="outline"
                            size="sm"
                            colorScheme={'teal'}
                            px={8}
                            onClick={() => {
                                setActiveFilterValues({ ...filterFormValues });
                                setPage(0);
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            variant="outline"
                            size="sm"
                            ms={3}
                            px={8}
                            onClick={() => {
                                setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                                setActiveFilterValues({ ...initialFilters });
                                setFilterFormValues({ ...initialFilters });
                                setPage(0);
                            }}
                        >
                            Reset
                        </Button>
                    </Box>
                </Grid>

                <TableContainer>
                    {loading && <Progress />}
                    <Table>
                        <Thead>
                            <Tr>
                                <Th align="left">Index</Th>
                                <Th align="left">Name</Th>
                                <Th align="left">Email address</Th>
                                <Th align="left">Status</Th>
                                <Th align="left">Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {students.map((student) => {
                                return (
                                    <Tr key={student._id}>
                                        <Td align="left">{student.indexNo}</Td>
                                        <Td align="left">{student?.studentId.name || ''}</Td>
                                        <Td align="left">{student?.studentId.email || ''}</Td>
                                        <Td align="left">
                                            <Tag size="sm" colorScheme={student.available ? 'teal' : 'red'}>
                                                {student.available ? 'Available' : 'Unavailable'}
                                            </Tag>
                                        </Td>
                                        <Td align="left">
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => {
                                                    changeAvailability(
                                                        student._id || 'undefined',
                                                        student?.studentId.name || '',
                                                        student.available
                                                    );
                                                }}
                                            >
                                                {/* <Edit size={16} /> */}
                                                Toggle Availability
                                            </Button>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <ReactPaginate
                    containerClassName="table-pagination"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={(e) => setPage(e.selected)}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(count / rowsPerPage)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </Card>
        </Box>
    );
}
