import { FormControl, FormLabel, FormErrorMessage, useColorModeValue, Text } from '@chakra-ui/react';
import { Props, Select } from 'chakra-react-select';
import { useController, UseControllerProps } from 'react-hook-form';

type ControlledSelectProps = UseControllerProps &
    Props & {
        label: string;
    };

export const ControlledSelect = ({ control, name, id, label, rules, ...props }: ControlledSelectProps) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
        rules,
    });
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');

    return (
        <FormControl isInvalid={invalid} id={id}>
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                {label}
                {rules?.required ? <Text color={brandStars}>*</Text> : null}
            </FormLabel>

            <Select name={name} ref={ref} onChange={onChange} onBlur={onBlur} value={value} {...props} />

            <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
    );
};
