import companyApiInstance from 'core/api/company.api';
import vacancyApi from 'core/api/vacancy.api';
import { useQuery } from 'react-query';

export function useCompanyListQuery() {
    const { data = [], isLoading } = useQuery(
        ['companies'],
        () => {
            return companyApiInstance.getCompanyList().then((res) => {
                return res.data.sort(function (a: any, b: any) {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
            });
        },
        { cacheTime: 1000 * 60 * 60 * 24 * 1 }
    );

    return { companies: data, isLoading };
}

export function useVacancyStatsQuery() {
    const {
        data = [
            { label: 'Total Vacancies', value: 0 },
            { label: 'Total Applications', value: 0 },
            { label: 'Unapplied Vacancies', value: 0 },
            { label: 'Applied Vacancies', value: 0 },
        ],
        isLoading,
    } = useQuery(
        ['vacancy-stats'],
        () => {
            return vacancyApi.getSummaryForList().then((res) => [
                {
                    label: 'Total Vacancies',
                    value: res.vacancyCount,
                },
                {
                    label: 'Total Applications',
                    value: res.applicationCount,
                },
                {
                    label: 'Unapplied Vacancies',
                    value: res.unappliedVacancyCount,
                },
                {
                    label: 'Applied Vacancies',
                    value: res.appliedVacancyCount,
                },
            ]);
        },
        { cacheTime: 1000 * 60 * 60 * 24 * 1 }
    );

    return { vacancyStats: data, isLoading };
}
