import axios from 'axios';

import { IResponse } from '../models/response.model';
import { StudentSummaryByCategory } from '../models/student-category-summary.model';
import { StudentSummary } from '../models/student-summary.model';
import {
    GetStudentProfileRequest,
    Student,
    StudentCreateRequest,
    StudentFilters,
    StudentPortfolio,
} from '../models/student.model';

import { baseUrl } from 'constant/baseUrl';

export class StudentApi {
    async createStudent(payload: StudentCreateRequest) {
        return axios.post(`${baseUrl}/student/register`, payload);
    }

    async getStudent() {
        const response = await axios.get(`${baseUrl}/student/profile`);
        return new Promise<GetStudentProfileRequest>((resolve, reject) => resolve(response.data));
    }

    async getSummary() {
        const response = await axios.get(`${baseUrl}/dashboard/student`);

        let summary: StudentSummary = {
            total_count: 0,
            selected_count: 0,
            pending_count: 0,
        };

        response.data.forEach((item: StudentSummary) => {
            summary.total_count += item.total_count;
            summary.selected_count += item.selected_count;
            summary.pending_count += item.pending_count;
        });

        // return summary as IResponse<StudentSummary>;
        return new Promise<StudentSummary>((resolve, reject) => resolve(summary));
    }

    async getStudents(filters: StudentFilters, page: number, rowsPerPage: number) {
        const response = await axios.get(`${baseUrl}/student`, { params: { ...filters, page, rowsPerPage } });
        return response.data as IResponse<Student>;
    }

    async getPortfolios({ level = 4, page = 1, degreeCode = '' }): Promise<IResponse<StudentPortfolio>> {
        return axios
            .get(
                `${baseUrl}/student/portfolio?level=${level}&page=${page}&rowsPerPage=9&degree=${
                    degreeCode !== '' ? degreeCode + (degreeCode !== 'SE' ? level.toString() : '') : ''
                }`
            )
            .then((res) => res.data);
    }

    async getSummaryByCategory() {
        let studentSummary: StudentSummaryByCategory[] = [];

        await axios.get(`${baseUrl}/dashboard/student`).then((res) => (studentSummary = res.data));

        console.log(studentSummary);

        return new Promise<StudentSummaryByCategory[]>((resolve, reject) => resolve(studentSummary));
    }

    async changeAvailability(id: string, available: boolean) {
        const response = await axios.patch(`${baseUrl}/student/update-availability`, null, {
            params: {
                id,
                available,
            },
        });
        return response.data as IResponse<Student>;
    }

    async getProfilePic(id: string) {
        const response = await axios.post(`${baseUrl}/student/profile-pic`, null, {
            params: {
                id,
            },
        });
        return new Promise<string>((resolve, reject) => resolve(response.data));
    }
}
const studentApi = new StudentApi();
export default studentApi;
