import React, { ReactNode } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';

interface CustomAlertDialogProps {
    isOpen: boolean;
    onClose: () => void;
    title: string | ReactNode;
    body: string | ReactNode;
    buttons: { label: string; onClick: () => void; colorScheme?: string }[];
}

const CustomAlertDialog: React.FC<CustomAlertDialogProps> = ({ isOpen, onClose, title, body, buttons }) => {
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={null}
            onClose={onClose}
            motionPreset="slideInBottom"
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>{title}</AlertDialogHeader>
                    <AlertDialogCloseButton autoFocus={false} />
                    <AlertDialogBody>{body}</AlertDialogBody>
                    <AlertDialogFooter>
                        {buttons.map((button, index) => (
                            <Button
                                autoFocus={true}
                                key={index}
                                colorScheme={button.colorScheme}
                                variant={button.colorScheme === 'dark' ? 'outline' : undefined}
                                onClick={() => {
                                    button.onClick();
                                    onClose(); // Automatically close the dialog after the action
                                }}
                                ml={index > 0 ? 3 : 0}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default CustomAlertDialog;
