import axios from 'axios';
import { baseUrl } from 'constant/baseUrl';
import {
    CompanyReservationsResponse,
    ReservationCreateOrUpdateRequest,
    ReservationModel,
    SubmitPaymentProofRequest,
    UpdateReservationType,
} from 'core/models/reservation.model';

export class ReservationApi {
    createReservation(data: ReservationCreateOrUpdateRequest) {
        return axios.post(`${baseUrl}/reservations`, data);
    }

    updateReservation(data: ReservationCreateOrUpdateRequest, reservationId: string) {
        return axios.patch(`${baseUrl}/reservations/${reservationId}`, data);
    }

    getReservations() {
        return axios.get<ReservationModel[]>(`${baseUrl}/reservations/by-company`);
    }

    getCompanyReservationByCompanyIdAdmin(id: string) {
        return axios.get<ReservationModel[]>(`${baseUrl}/reservations/by-company/id/${id}`);
    }

    getCompanyReservationByReservationId(id: string) {
        return axios.get<ReservationModel>(`${baseUrl}/reservations/by-company/${id}`);
    }

    submitProofofPayment(payload: SubmitPaymentProofRequest) {
        return axios.patch(`${baseUrl}/reservations/updateProof`, payload);
    }

    // get all reservations for all companies that are not rejected or canceled
    async getCompanyReservationsList() {
        return axios.get<CompanyReservationsResponse>(`${baseUrl}/reservations/company`);
    }

    async acceptOrRejectReservation(ReservationId: string, status: UpdateReservationType) {
        const response = await axios.patch(`${baseUrl}/reservations/${status}`, {
            reservationId: ReservationId,
        });
        return response.data;
    }
}

const reservationApi = new ReservationApi();
export default reservationApi;
