import { LockIcon } from '@chakra-ui/icons';
import { Alert, AlertDescription, AlertTitle, Box } from '@chakra-ui/react';

export default function Overview() {
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Alert
                status="info"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
            >
                <LockIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                    This Page Is Currently not Available to Companies
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                    We will notify you once we have made this section available for the companies.
                </AlertDescription>
            </Alert>
        </Box>
    );
}
