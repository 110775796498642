import { ViewIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { Vacancy } from 'core/models/vacancy.model';

export function ViewAdvertModel({ vacancy }: { vacancy: Vacancy }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button colorScheme="teal" size="sm" onClick={onOpen}>
                <ViewIcon /> &nbsp; View Advert
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent py={3}>
                    <ModalHeader> {vacancy.jobTitle}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box whiteSpace={'pre-line'} mb={3}>
                            {vacancy.description}
                        </Box>
                        {vacancy.adURL.includes('.pdf?') ? (
                            <embed style={{ width: '100%', aspectRatio: '.9' }} src={vacancy.adURL}></embed>
                        ) : (
                            <Image src={vacancy.adURL} mt="1rem" />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
