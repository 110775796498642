import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    HStack,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Progress,
    Select,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import studentApi from 'core/api/student.api';
import { StudentFilters, StudentPortfolio } from 'core/models/student.model';
import { Student } from 'core/models/user.model';
import { useEffect, useState } from 'react';
import { FaGlobeAsia, FaLinkedinIn } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

export interface AdminStallManagementProps {}

export interface AdminCandidateManagementProps {}

const initialFilters: StudentFilters = {
    name: '',
    email: '',
    regNo: '',
    indexNo: '',
    degree: '',
    level: '',
    available: '',
};
const isValidURL = (str: string) => {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            'localhost|' + // localhost
            '((\\d{1,3}\\.){3}\\d{1,3}))|' + // OR ip (v4) address
            '((\\w+\\.)+(com|net|org|edu|gov|mil|biz|info|me|io|dev))$' // OR domain name + TLD
    );
    return !!pattern.test(str);
};

function CandidateCard({ student }: { student: StudentPortfolio }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Card
                onClick={onOpen}
                border="1px solid transparent"
                transition={'all 0.3s ease'}
                borderRadius="md"
                color={'gray.500'}
                cursor="pointer"
                _hover={{ borderColor: 'gray.700', color: 'gray.700' }}
            >
                <HStack justifyItems={'flex-start'}>
                    <div
                        style={{
                            backgroundImage: `url('${
                                student.profilePic ? student.profilePic : 'https://placehold.co/100x100?text=😁'
                            }')`,
                            height: '5rem',
                            width: '5rem',
                            borderRadius: '1rem',
                            backgroundSize: 'cover',
                        }}
                    />
                    <Stack alignItems={'start'} spacing={0}>
                        <Text fontWeight={'bold'}>{student.name}</Text>
                        <Text fontSize={'sm'}>{student.degree}</Text>
                        <Text fontSize={'sm'}>Year : {student.level}</Text>
                    </Stack>
                </HStack>
                <Text mt={2} className="portfolio-description" overflow="hidden" textOverflow="ellipsis">
                    {student.description}
                </Text>
            </Card>
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody py={10}>
                        <Stack alignItems={'center'} justifyItems={'flex-start'}>
                            <div
                                style={{
                                    backgroundImage: `url('${
                                        student.profilePic ? student.profilePic : 'https://placehold.co/100x100?text=😁'
                                    }')`,
                                    height: '8rem',
                                    width: '8rem',
                                    borderRadius: '1rem',
                                    backgroundSize: 'cover',
                                }}
                            />
                            <Stack alignItems={'center'} spacing={0}>
                                <Text fontSize={'lg'} fontWeight={'bold'}>
                                    {student.name}
                                </Text>
                                <Text>{student.degree}</Text>
                                <Text>Year : {student.level}</Text>
                            </Stack>
                        </Stack>
                        <Stack alignItems={'center'} spacing={0} my={3} mb={6}>
                            {student.personalSite && isValidURL(student.personalSite) && (
                                <Link
                                    href={
                                        (student.personalSite.toLowerCase().includes('http') ? '' : '//') +
                                        student.personalSite
                                    }
                                    target="_blank"
                                >
                                    <HStack>
                                        <FaGlobeAsia />
                                        <Text>{student.personalSite}</Text>
                                    </HStack>
                                </Link>
                            )}
                            {student.linkedIn && student.linkedIn.includes('/in/') && (
                                <Link href={student.linkedIn} target="_blank">
                                    <HStack>
                                        <FaLinkedinIn />
                                        <Text>{'/' + student.linkedIn.split('/in/')[1].replace(/\/+$/, '')}</Text>
                                    </HStack>
                                </Link>
                            )}
                        </Stack>
                        <Text mt={2} overflow="hidden" textOverflow="ellipsis">
                            {student.description}
                        </Text>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default function Candidates() {
    const [loading, setLoading] = useState(true);

    const [count, setCount] = useState(10);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [students, setStudents] = useState<Student[] | any[]>([]);

    const [filterFormValues, setFilterFormValues] = useState({ ...initialFilters });
    const [activeFilterValues, setActiveFilterValues] = useState({ ...initialFilters });

    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setLoading(true);

        studentApi
            .getPortfolios({
                level: parseInt(activeFilterValues.level || '4'),
                page,
                degreeCode: activeFilterValues.degree,
            })
            .then((res) => {
                setStudents(res.data);
                setCount(res.count);
                setPage(res.page);
                setRowsPerPage(res.rowsPerPage);
                setLoading(false);
            })
            .catch((e) => {
                setIsError(true);
                console.log('Error fetching student: ', e);
            });
    }, [activeFilterValues, page, rowsPerPage, shouldRefresh]);

    return (
        <Box pt={{ base: '50px', md: '50px', xl: '50px' }}>
            <Box flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <Grid gridTemplateColumns={'1fr 1fr 1fr 1fr'} placeItems="end" gap={3}>
                    <FormControl size="small">
                        <FormLabel id="degree-label">Degree</FormLabel>
                        <Select
                            id="degree"
                            name="degree"
                            size="sm"
                            value={filterFormValues.degree}
                            onChange={(event) =>
                                setFilterFormValues({
                                    ...filterFormValues,
                                    [event.target.name || 'name_missing']: event.target.value,
                                })
                            }
                        >
                            <option value={''}>All</option>
                            <option value={'IS'}>Information Systems</option>
                            <option value={'CS'}>Computer Science</option>
                            <option value={'SE'}>Software Engineering</option>
                        </Select>
                    </FormControl>

                    <FormControl size="small">
                        <FormLabel id="level-label">Level</FormLabel>
                        <Select
                            id="level"
                            name="level"
                            size="sm"
                            value={filterFormValues.level}
                            onChange={(event) =>
                                setFilterFormValues({
                                    ...filterFormValues,
                                    [event.target.name || 'name_missing']: event.target.value,
                                })
                            }
                        >
                            <option value={''}>All</option>
                            <option value={'3'}>Third year</option>
                            <option value={'4'}>Fourth year</option>
                        </Select>
                    </FormControl>
                    <Box gridColumn={'span 2'}>
                        <Button
                            variant="outline"
                            size="sm"
                            colorScheme={'teal'}
                            px={8}
                            onClick={() => {
                                setActiveFilterValues({ ...filterFormValues });
                                setPage(0);
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            variant="outline"
                            size="sm"
                            ms={3}
                            px={8}
                            onClick={() => {
                                setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                                setActiveFilterValues({ ...initialFilters });
                                setFilterFormValues({ ...initialFilters });
                                setPage(0);
                            }}
                        >
                            Reset
                        </Button>
                    </Box>
                </Grid>
            </Box>

            {isError && (
                <Alert status="error" size="xs" style={{ marginTop: '10px' }}>
                    <AlertIcon />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <AlertTitle style={{ marginBottom: '4px' }}>An error occurred</AlertTitle>
                        <AlertDescription>Try refreshing the page</AlertDescription>
                    </div>
                </Alert>
            )}

            {loading && <Progress />}
            <Grid gridTemplateColumns="repeat(auto-fit, minmax(300px, 2fr))" gap={3} mt={6}>
                {students.map((student) => {
                    return <CandidateCard student={student} key={student.name} />;
                })}
            </Grid>
            <HStack justifyContent={'center'} mt={3}>
                <ReactPaginate
                    containerClassName="table-pagination"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={(e) => setPage(e.selected)}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(count / rowsPerPage)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </HStack>
        </Box>
    );
}
