import { IResponse } from '../models/response.model';
import axios from 'axios';
import { baseUrl } from 'constant/baseUrl';

import { Vacancy, VacancyFilters } from '../models/vacancy.model';
import { VacancySummary, VacancySummaryByCategory } from '../models/vacancy-summary.model';

const vacancies: Vacancy[] = [
    {
        jobTitle: 'Software Engineer',
        description: 'lorem ipsum',
        adURL: 'random',
    },
    {
        jobTitle: 'Software Engineer',
        description: 'lorem ipsum',
        adURL: 'random',
    },
    {
        jobTitle: 'Software Engineer',
        description: 'lorem ipsum',
        adURL: 'random',
    },
    {
        jobTitle: 'Software Engineer',
        description: 'lorem ipsum',
        adURL: 'random',
    },
];

export class VacancyApi {
    getVacancies() {
        return new Promise<Vacancy[]>((resolve, reject) => resolve(vacancies));
    }

    addVacancy(vacancy: Vacancy, companyId: string) {
        return axios.post<Vacancy>(`${baseUrl}/vacancy/${companyId}`, vacancy);
    }

    editVacancy(vacancyId: string, vacancy: Partial<Vacancy>) {
        return axios.patch<Vacancy>(`${baseUrl}/vacancy/${vacancyId}`, vacancy);
    }

    deleteVacancy(vacancyId: string) {
        return axios.delete<Vacancy>(`${baseUrl}/vacancy/${vacancyId}`);
    }

    markDownload(vacancyId: string, applicationId?: string) {
        return axios.post<{ success: true }>(
            `${baseUrl}/vacancy/${vacancyId}/download${applicationId ? '?applicationId=' + applicationId : ''}`
        );
    }

    getSummary() {
        const summary: VacancySummary = {
            available_vacancy_count: 19,
            applicants_count: 54,
        };

        return new Promise<VacancySummary>((resolve, reject) => resolve(summary));
    }

    async getSummaryForList() {
        const response = await axios.get(`${baseUrl}/dashboard/vacancy_summary`);
        return response.data as VacancySummaryByCategory;
    }

    async getAllVacancies(filters: VacancyFilters, page: number, rowsPerPage: number) {
        const response = await axios.get(`${baseUrl}/vacancy`, { params: { ...filters, page, rowsPerPage } });
        return response.data as IResponse<Vacancy>;
    }

    async getAllVacanciesForCompany(companyId: string): Promise<Vacancy[]> {
        const response = await axios.get<Vacancy[]>(`${baseUrl}/vacancy/company/${companyId}`);
        return new Promise<Vacancy[]>((resolve, reject) => resolve(response.data));
    }

    getStudentCVs() {
        return axios.get(`${baseUrl}/student/cv/list`);
    }

    candidateApplyVacancy(payload: any) {
        return axios.post(`${baseUrl}/application`, payload);
    }

    cancelApplication(applicationId: any) {
        return axios.delete(`${baseUrl}/application/${applicationId}`);
    }
}

const vacancyApi = new VacancyApi();
export default vacancyApi;
