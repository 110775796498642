import { Box, Flex } from '@chakra-ui/react';

import logo from 'assets/img/logo.png';

export function SidebarBrand() {
    return (
        <Flex alignItems="center" flexDirection="column">
            {/* <PortalDashboardLogo h="30px" w="175px" my="25px" color={logoColor} /> */}
            <Box mb={10}>
                <img src={logo} alt="Logo" style={{ height: '6rem' }} />
            </Box>
        </Flex>
    );
}

export default SidebarBrand;
