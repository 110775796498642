import { useState } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Progress,
    Text,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useDropzone } from 'react-dropzone';
import { CompanyCreateRequest } from 'core/models/company.model';
import companyApi from 'core/api/company.api';

export default function CreateCompanyModal(props: { isOpen: boolean; btnRef: any; onClose: () => void }) {
    const { isOpen, onClose } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ reValidateMode: 'onBlur' });
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: ['.png', '.jpg', '.jpeg'],
        maxFiles: 1,
    });
    const toast = useToast();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const bg = useColorModeValue('gray.100', 'navy.700');
    const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('brand.500', 'brand.400');

    const handleClick = () => setShow(!show);

    const files = acceptedFiles.map((file) => (
        <Text fontSize="xs" key={`${file.name}${file.size}`} color="secondaryGray.700">
            {file.name}
        </Text>
    ));

    const onSubmit = async (data: any) => {
        setLoading(true);
        let company: CompanyCreateRequest = {
            user: {
                email: data.email,
                name: data.name,
                password: data.password,
                role: 'company',
            },
            name: data.name,
            description: data.description,
            email: data.email,
            address: data.address,
            contactNo: data.contactNo,
            contactPerson: data.contactPerson,
            flyer01: `https://placehold.co/400x600?text=${data.name}`,
            flyer02: `https://placehold.co/400x600?text=${data.name}`,
            video: 'https://placehold.co/600x400',
        };
        if (data.webURL) company.webURL = data.webURL;
        try {
            // if (acceptedFiles.length > 0) {
            //     var payload = new FormData();
            //     payload.append('file', acceptedFiles[0]);
            //     const fileUploadRes = await fileApi.uploadFile(payload);
            //     if (!fileUploadRes.data.url) throw Error;
            //     company.logoURL = fileUploadRes.data.url;
            // } else
            company.logoURL = `https://placehold.co/400x400/EEE/31343C?font=raleway&text=${company.name}`;
            await companyApi.createCompanyWithUserAccount(company);
            toast({
                title: 'Success!',
                description: 'Company created successfully',
                status: 'success',
                variant: 'solid',
                duration: 9000,
                isClosable: true,
            });
            setLoading(false);
            onClose();
        } catch (error: any) {
            console.error(error);
            setLoading(false);
            toast({
                title: 'Request Failed!',
                description: `The request failed to process: ${error?.message}`,
                status: 'error',
                variant: 'solid',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Company</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction="column" gap="4" as="form" onSubmit={handleSubmit(onSubmit)}>
                        {loading && <Progress isIndeterminate size="xs" width="100%" colorScheme="red" />}
                        <Text fontSize="xl" fontWeight="bold">
                            Account Details
                        </Text>
                        <FormControl id="email" isInvalid={!!errors.email?.message} isDisabled={loading}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Email <Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                type="email"
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                placeholder="mail@company.com"
                                fontWeight="500"
                                size="lg"
                                {...register('email', { required: 'Email address is required' })}
                            />
                            <FormErrorMessage color={brandStars} fontSize="sm" fontWeight="semibold">
                                {`*${errors.email?.message}` || ''}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl id="password" isInvalid={!!errors.password?.message} isDisabled={loading}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Password <Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size="md">
                                <Input
                                    fontSize="sm"
                                    {...register('password', { required: true })}
                                    size="lg"
                                    type={show ? 'text' : 'password'}
                                    variant="auth"
                                    {...register('password', { required: 'Password is required' })}
                                />
                                <InputRightElement display="flex" alignItems="center" mt="4px">
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: 'pointer' }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage color={brandStars} fontSize="sm" fontWeight="semibold">
                                {`*${errors.password?.message}` || ''}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl id="name" isInvalid={!!errors.name?.message} isDisabled={loading}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Company Name <Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                type="text"
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                size="lg"
                                {...register('name', { required: 'Company name is required' })}
                            />
                            <FormErrorMessage color={brandStars} fontSize="sm" fontWeight="semibold">
                                {`*${errors.name?.message}` || ''}
                            </FormErrorMessage>
                        </FormControl>

                        <Flex justifyContent="end">
                            <Button variant="action" mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button variant="brand" type="submit">
                                Save
                            </Button>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
