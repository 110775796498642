import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormLabel,
    Grid,
    Heading,
    Input,
    Skeleton,
    Stack,
    Text,
    Textarea,
    useColorModeValue,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import companyApi from 'core/api/company.api';
import { Company, CompanyUpdateRequest } from 'core/models/company.model';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import React, { useEffect, useState } from 'react';
import reservationApi from '../../../core/api/reservation.api';
import { ReservationModel } from '../../../core/models/reservation.model';
import UserProfile from '../../admin/profile/components/UserProfile';
import { isCompanyDetailsFilled } from '../../../hooks/useCompanyRoutes';
import { formatCurrency } from '../stall-booking';

export default function Overview() {
    const auth = useAuth();
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const toast = useToast();

    const {
        data: company,
        isLoading,
        refetch,
    } = useQuery<Company>({
        queryKey: ['company', auth.user.companyId],
        queryFn: () => companyApi.getCompany(auth.user.companyId).then((res) => res.data),
    });

    const { data: reservation, isLoading: isLoadingReservation } = useQuery<ReservationModel>({
        queryKey: ['reservation-company', auth.user.companyId],
        queryFn: () => reservationApi.getCompanyReservationByReservationId(auth.user.companyId).then((res) => res.data),
    });

    const [formData, setFormData] = useState<CompanyUpdateRequest>({});
    const [initialData, setInitialData] = useState<CompanyUpdateRequest>({});

    const [alertMessage, setAlertMessage] = useState<string | null>(null);

    useEffect(() => {
        if (company) {
            const initial = {
                name: company.name || '',
                address: company.address || '',
                webURL: company.webURL || '',
                description: company.description || '',
                contactPerson: company.contactPerson || '',
                contactNo: company.contactNo || '',
                logoURL: company.logoURL || null,
            };
            setFormData(initial);
            setInitialData(initial);
        }
    }, [company]);

    const handleChange = (field: keyof CompanyUpdateRequest, value: string) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const isFormChanged = () => {
        return JSON.stringify(formData) !== JSON.stringify(initialData);
    };

    const validateForm = () => {
        const requiredFields: (keyof CompanyUpdateRequest)[] = [
            'name',
            'address',
            'webURL',
            'contactPerson',
            'contactNo',
        ];

        // Check for general required fields
        const allFieldsFilled = requiredFields.every((field) => formData[field] && formData[field] !== '');

        if (!allFieldsFilled) {
            setAlertMessage('Please fill all required fields.');
            return false;
        }

        // Additional validation for webURL
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/i;
        if (!urlRegex.test(formData.webURL)) {
            setAlertMessage('Website URL is invalid.');
            return false;
        }

        // Additional validation for contactNo
        const contactNoRegex = /^0\d{9}$/;
        if (!contactNoRegex.test(formData.contactNo)) {
            setAlertMessage('Contact number is invalid.');
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        console.log(formData);
        setAlertMessage(null);
        if (!validateForm()) {
            window.scroll({
                top: 0,
            });
            return;
        }

        try {
            await companyApi.updateCompany(auth.user.companyId, formData);
            if (formData.name) {
                localStorage.setItem('name', formData.name);
            }
            auth.update();
            refetch();
            toast({
                title: 'Success!',
                description: 'Company profile updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            console.error(err);
            setAlertMessage("We couldn't update the details. Please try again.");
            window.scroll({
                top: 0,
            });
        }
    };

    return (
        <Box pt={{ base: '200px', md: '120px', lg: '120px' }}>
            {isLoading ? (
                <Stack>
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                </Stack>
            ) : (
                <Card mb={{ base: '0px', lg: '20px' }} pb={'30px'} p={{ base: 4, md: 6, lg: 8 }}>
                    <Flex alignItems="center" direction="column">
                        {/* <Box borderRadius="16px" w="100%">
                                <video
                                    key="djsaodjoad"
                                    controls
                                    autoPlay
                                    loop
                                    muted
                                    style={{ width: '100%', borderRadius: '16px', height: '260px' }}
                                >
                                    <source src={company.video} type="video/mp4" />
                                </video>
                            </Box> */}
                        <UserProfile
                            image={formData.logoURL}
                            companyName={formData.name}
                            style={{ marginTop: '-80px' }}
                            updateLogoUrl={(data: CompanyUpdateRequest) => handleChange('logoURL', data.logoURL)}
                            setAlertMessage={setAlertMessage}
                        />
                    </Flex>

                    {!isLoading && !isCompanyDetailsFilled(company) && (
                        <Alert variant={'left-accent'} status="error" size={'xs'} mt={2} mb={3}>
                            <AlertIcon />
                            <Heading size={'sm'} fontWeight={'normal'}>
                                Please complete the Company Profile to proceed to <strong>Stall Reservation</strong>.
                            </Heading>
                        </Alert>
                    )}

                    {!isLoading &&
                        !isLoadingReservation &&
                        reservation &&
                        reservation.paymentAmount > 0 &&
                        !company.isPaid &&
                        !reservation.paymentProof && (
                            <Alert variant={'left-accent'} status="warning" mb={3}>
                                <AlertIcon />
                                <AlertDescription>
                                    <Heading size={'sm'} fontWeight={'normal'}>
                                        You have pending payments
                                    </Heading>
                                    <small>
                                        {`You have ${formatCurrency(reservation.paymentAmount)} to be paid. `}
                                        If you have already paid, please contact{' '}
                                        <a href={''} style={{ fontStyle: 'italic' }}>
                                            careerfair@ucsc.cmb.ac.lk
                                        </a>{' '}
                                        for support
                                    </small>
                                </AlertDescription>
                            </Alert>
                        )}

                    {alertMessage && (
                        <Alert status="warning" mb={4}>
                            <AlertIcon />
                            <AlertDescription>{alertMessage}</AlertDescription>
                        </Alert>
                    )}
                    <Grid gridTemplateColumns={'repeat(auto-fit, minmax(350px, 1fr))'} gap={8} mt={2}>
                        <Flex direction="column" gap={7} mb={4}>
                            <Text fontSize="xl" fontWeight="700">
                                Company Details
                            </Text>
                            <VStack spacing={1} alignItems="start">
                                <FormLabel>
                                    Company Name&nbsp;
                                    <Text as={'span'} color={brandStars}>
                                        *
                                    </Text>
                                </FormLabel>
                                <Input
                                    readOnly
                                    disabled
                                    defaultValue={company.name}
                                    placeholder="Enter company name"
                                    onChange={(e) => handleChange('name', e.target.value)}
                                />
                            </VStack>

                            <VStack spacing={1} alignItems="start">
                                <FormLabel>
                                    Company Address&nbsp;
                                    <Text as={'span'} color={brandStars}>
                                        *
                                    </Text>
                                </FormLabel>
                                <Input
                                    required
                                    defaultValue={company.address}
                                    placeholder="Enter company address"
                                    onChange={(e) => handleChange('address', e.target.value)}
                                />
                            </VStack>

                            <VStack spacing={1} alignItems="start">
                                <FormLabel>
                                    Company Website URL&nbsp;
                                    <Text as={'span'} color={brandStars}>
                                        *
                                    </Text>
                                </FormLabel>
                                <Input
                                    required
                                    defaultValue={company.webURL}
                                    placeholder="Enter company website"
                                    type="url"
                                    onChange={(e) => handleChange('webURL', e.target.value)}
                                />
                            </VStack>

                            <VStack spacing={1} alignItems="start">
                                <FormLabel>Company Description / Introduction</FormLabel>
                                <Textarea
                                    rows={4}
                                    defaultValue={company.description}
                                    placeholder="Enter company description"
                                    onChange={(e) => handleChange('description', e.target.value)}
                                />
                            </VStack>
                        </Flex>

                        <Flex direction="column" gap={7}>
                            <Text fontSize="xl" fontWeight="700">
                                Contact Details
                            </Text>
                            <VStack spacing={1} alignItems="start">
                                <FormLabel>
                                    Contact Person Name&nbsp;
                                    <Text as={'span'} color={brandStars}>
                                        *
                                    </Text>
                                </FormLabel>
                                <Input
                                    required
                                    defaultValue={company.contactPerson}
                                    placeholder="Enter contact person name"
                                    onChange={(e) => handleChange('contactPerson', e.target.value)}
                                />
                            </VStack>

                            <VStack spacing={1} alignItems="start">
                                <FormLabel>
                                    Contact Number&nbsp;
                                    <Text as={'span'} color={brandStars}>
                                        *
                                    </Text>
                                </FormLabel>
                                <Input
                                    required
                                    defaultValue={company.contactNo}
                                    placeholder="Enter contact number (eg: 0701234567)"
                                    type="tel"
                                    onChange={(e) => handleChange('contactNo', e.target.value)}
                                />
                            </VStack>
                            <Alert variant={'left-accent'} status="info">
                                <AlertIcon />
                                <AlertDescription>
                                    Please update the logo and company details and email a publicly accessible link of
                                    the company video to &nbsp;
                                    <a href="mailto:careerfair@ucsc.cmb.ac.lk">
                                        <u>careerfair@ucsc.cmb.ac.lk</u>
                                    </a>
                                </AlertDescription>
                            </Alert>
                        </Flex>
                    </Grid>
                    <Flex mt={4} justifyContent="center">
                        <Button colorScheme="brand" onClick={handleSubmit} disabled={!isFormChanged()}>
                            Update
                        </Button>
                    </Flex>
                </Card>
            )}
        </Box>
    );
}
