import { useState } from 'react';
// Chakra imports
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/banner-2025.png';
import logo from 'assets/img/logo.png';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { auth } from './../../../firebase';

function ResetPassword() {
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ reValidateMode: 'onBlur' });

    const onSubmit = async (data: any) => {
        console.log(data);
        setLoading(true);
        setIsSuccess(false);
        setError('');
        sendPasswordResetEmail(auth, data.email)
            .then(() => {
                setIsSuccess(true);
            })
            .catch((e) => {
                if (e.code === 'auth/user-not-found') {
                    setError('A user with this email does not exist.');
                } else {
                    setError(e.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        // data.email;
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '20vh' }}
                flexDirection="column"
            >
                <Box mb={4} style={{ width: '100%' }} mt={-4}>
                    <img src={logo} alt="Logo" style={{ height: '8rem', margin: '0 auto' }} />
                </Box>
                <Box me="auto">
                    <Heading color={textColor} fontSize="28px" mb="10px">
                        Reset Password
                    </Heading>
                    <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                        Enter your email to reset your password
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '420px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                    as="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormControl isInvalid={!!errors.email?.message}>
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="text"
                            placeholder="mail@company.com"
                            fontWeight="500"
                            size="lg"
                            {...register('email', { required: 'Email Address is required' })}
                        />
                        <FormErrorMessage> {`${errors.email?.message}` || ''}</FormErrorMessage>
                    </FormControl>
                    {isSuccess ? (
                        <Alert variant={'left-accent'} status="success" mt={6}>
                            <AlertIcon /> Password reset instructions has been sent to your email.
                        </Alert>
                    ) : null}
                    {error ? (
                        <Alert variant={'left-accent'} status="error" mt={6}>
                            <AlertIcon /> {error}
                        </Alert>
                    ) : null}
                    <Button variant="brand" w="100%" h="50" mt={6} mb="24px" type="submit" isLoading={loading}>
                        Reset Password
                    </Button>
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="10px">
                        <Text color={textColorDetails} fontWeight="400" fontSize="14px" align="center">
                            <NavLink to="/auth/sign-in">
                                <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                                    Go Back to Login
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ResetPassword;
