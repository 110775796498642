import { useContext, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/banner-2025.png';
import logo from 'assets/img/logo.png';
import axios from 'axios';
import { AuthContext } from 'contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

function SignIn() {
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ reValidateMode: 'onBlur' });

    const [show, setShow] = useState(false);

    let history = useHistory();

    const redirectToHome = (role: string) => {
        console.log(role);
        if (role === 'student') {
            history.push('/student');
        } else if (role === 'company') {
            history.push('/company');
        } else if (role === 'admin') {
            history.push('/admin');
        } else if (role === 'unknown') {
            history.push('/main-hall');
        } else {
            history.push('/');
        }
    };

    const handleClick = () => setShow(!show);
    const authContext = useContext(AuthContext);

    const onSubmit = async (data: any) => {
        console.log(data);
        setLoading(true);

        try {
            const res = await authContext.login(data.email, data.password);
            console.log(res);
            if (res.success === false) {
                let error;

                // @ts-ignore
                switch (res.errorMessage?.code) {
                    case 'auth/network-request-failed':
                        error = 'Please check your internet connection';
                        break;
                    case 'auth/user-not-found':
                        error = 'Incorrect email or password';
                        break;
                    case 'auth/wrong-password':
                        error = 'Incorrect email or password';
                        break;
                    case 'auth/user-disabled':
                        error = 'User account disabled, please contact admin';
                        break;
                    default:
                        error = 'Something went wrong, please try again';
                        break;
                }

                throw new Error(error);
            }
            const user = await axios.get(`/users/${res.user.user_id}`);
            console.log(user.data);

            if (user.data != null) {
                localStorage.setItem('name', user.data.name);
                localStorage.setItem('email', user.data.email);
                localStorage.setItem('role', user.data.role);

                setLoginError(null);
                redirectToHome(user.data.role);
            } else {
                setLoginError(new Error('User data not found in database'));
            }
        } catch (error) {
            console.log('catch');
            setLoginError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '20px', md: '10vh' }}
                flexDirection="column"
            >
                <Box mb={4} style={{ width: '100%' }} mt={-4}>
                    <img src={logo} alt="Logo" style={{ height: '8rem', margin: '0 auto' }} />
                </Box>
                <Box me="auto">
                    <Heading color={textColor} fontSize="28px" mb="10px">
                        Sign In
                    </Heading>
                    <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                        Enter your email and password to sign in!
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '420px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                    as="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormControl isInvalid={!!errors.email?.message} mb="12px">
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="email"
                            placeholder="Enter your email address"
                            fontWeight="500"
                            size="lg"
                            {...register('email', { required: 'Email Address is required' })}
                        />
                        <FormErrorMessage> {`${errors.email?.message}` || ''}</FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                            Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size="md">
                            <Input
                                mb={'12px'}
                                fontSize="sm"
                                placeholder="Enter your password"
                                {...register('password', { required: true })}
                                size="lg"
                                type={show ? 'text' : 'password'}
                                variant="auth"
                            />
                            <InputRightElement display="flex" alignItems="center" mt="4px">
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: 'pointer' }}
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Flex justifyContent="space-between" align="center" mb="24px">
                            <FormControl display="flex" alignItems="center">
                                {/* <Checkbox id="remember-login" colorScheme="brandScheme" me="10px" />
                                <FormLabel
                                    htmlFor="remember-login"
                                    mb="0"
                                    fontWeight="normal"
                                    color={textColor}
                                    fontSize="sm"
                                >
                                    Keep me logged in
                                </FormLabel> */}
                            </FormControl>
                            <NavLink to="/auth/reset-password">
                                <Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
                                    Forgot password?
                                </Text>
                            </NavLink>
                        </Flex>
                        {loginError ? (
                            <Alert variant={'left-accent'} status="error" mb={'5'}>
                                <AlertIcon /> {loginError?.message}
                            </Alert>
                        ) : null}
                        <Button variant="brand" w="100%" h="50" mb="24px" type="submit" isLoading={loading}>
                            Sign In
                        </Button>
                    </FormControl>

                    <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="10px">
                        <Flex
                            flexDirection="column"
                            color={textColorDetails}
                            fontWeight="400"
                            fontSize="14px"
                            align="center"
                        >
                            Not registered yet ? <br />
                            <Flex style={{ marginTop: '1rem', alignItems: 'center' }}>
                                <div style={{ flex: '1' }}>
                                    <div style={{ marginTop: '.5rem', fontWeight: 'bold' }}>Organizations</div>
                                    <span>Contact&nbsp;us&nbsp;via:</span>
                                    <a href="mailto:careerfair@ucsc.cmb.ac.lk">
                                        <Text color={textColorBrand} as="div" fontWeight="500">
                                            careerfair@ucsc.cmb.ac.lk
                                        </Text>
                                    </a>
                                </div>
                                <div
                                    style={{
                                        width: '1px',
                                        background: 'black',
                                        margin: '0 2rem',
                                        height: '2.5rem',
                                    }}
                                ></div>
                                <div style={{ flex: '1' }}>
                                    <div style={{ marginTop: '.5rem', fontWeight: 'bold' }}>Undergraduates</div>
                                    Register now via:
                                    <NavLink to="/auth/sign-up">
                                        <Text color={textColorBrand} as="div" fontWeight="500">
                                            Student Registration
                                        </Text>
                                    </NavLink>
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;
