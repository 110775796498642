import { EditIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import { CompanyWithoutVacancies } from 'core/models/company.model';
import * as React from 'react';
// Assets
import CreateCompanyModal from './CreateCompanyModal';
import EditCompanyDrawer from './EditCompanyDrawer';

const columnHelper = createColumnHelper<CompanyWithoutVacancies>();

// const columns = columnsDataCheck;
export default function CompanyTable(props: { tableData: CompanyWithoutVacancies[]; refreshData: () => void }) {
    const { tableData, refreshData } = props;
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [company, setCompany] = React.useState<CompanyWithoutVacancies>();
    const editBtnRef = React.useRef();
    const createCompanyBtnRef = React.useRef();
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    let defaultData = tableData;
    const columns = [
        columnHelper.accessor((row) => row.logoURL, {
            id: 'logo',
            cell: (info) => info.getValue(),
            header: () => <></>,
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => row, {
            id: 'name',
            cell: (info) => (
                <span style={{ lineHeight: '1rem' }}>
                    <b style={{ fontWeight: 600 }}> {info.getValue().name}</b> <br />
                    <div style={{ maxWidth: '15rem', whiteSpace: 'pre-line', fontSize: '.7rem' }}>
                        {info.getValue().address}
                    </div>
                </span>
            ),
            header: () => <span>Company Name</span>,
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => row.contactPerson, {
            id: 'contactPerson',
            cell: (info) => <div style={{ maxWidth: '10rem', whiteSpace: 'pre-line' }}>{info.getValue()}</div>,
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => row, {
            id: 'contactDetails',
            cell: (info) => (
                <span>
                    {info.getValue().contactNo}
                    <br />
                    {info.getValue().email}
                </span>
            ),
            header: () => <span>Contact Details</span>,
        }),

        columnHelper.display({
            id: 'actions',
            header: () => <span>Actions</span>,
            cell: (props) => (
                <Flex align="center">
                    <Button
                        size={'sm'}
                        variant="outline"
                        ref={editBtnRef}
                        onClick={() => {
                            setCompany(props.row.original);
                            onDrawerOpen();
                        }}
                    >
                        <EditIcon />
                        &nbsp; Edit
                    </Button>
                </Flex>
            ),
        }),
    ];
    const data = [...defaultData];
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
            <Box>
                <CreateCompanyModal
                    isOpen={isModalOpen}
                    btnRef={createCompanyBtnRef}
                    onClose={() => {
                        refreshData();
                        onModalClose();
                    }}
                />
                <EditCompanyDrawer
                    isOpen={isDrawerOpen}
                    btnRef={editBtnRef}
                    onClose={() => {
                        refreshData();
                        onDrawerClose();
                    }}
                    data={company}
                />
                <HStack alignItems="end" px={6} pb={3} justifyContent="space-between">
                    <Heading size={'md'} fontWeight={400}>
                        Total Registered Companies : {tableData.length}
                    </Heading>
                    <Button variant="brand" ref={createCompanyBtnRef} onClick={onModalOpen}>
                        Create Company
                    </Button>
                </HStack>
                <TableContainer>
                    <Table mb="24px" mt="12px">
                        <Thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <Tr key={headerGroup.id}>
                                    <Th borderColor={borderColor}>#</Th>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <Th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                pe="10px"
                                                borderColor={borderColor}
                                                cursor="pointer"
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: '',
                                                            desc: '',
                                                        }[header.column.getIsSorted() as string] ?? null}
                                                    </div>
                                                )}
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody>
                            {tableData.length > 0 ? (
                                table.getRowModel().rows.map((row) => {
                                    return (
                                        <Tr key={row.id}>
                                            <Td>{row.index + 1}</Td>
                                            {row.getVisibleCells().map((cell) => {
                                                return (
                                                    <Td
                                                        key={cell.id}
                                                        fontSize={{ sm: '14px' }}
                                                        borderColor="transparent"
                                                    >
                                                        {cell.column.id === 'logo' ? (
                                                            <Box>
                                                                <Image
                                                                    src={cell.getValue() as string}
                                                                    maxHeight="3rem"
                                                                    maxWidth={'4rem'}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <Flex flexWrap="wrap">
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}
                                                            </Flex>
                                                        )}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                    );
                                })
                            ) : (
                                <Tr>
                                    <Td colSpan={6}>
                                        <Alert status="warning" borderRadius="5px" justifyContent="center">
                                            <AlertIcon />
                                            <AlertDescription>No companies found.</AlertDescription>
                                        </Alert>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Card>
    );
}
