import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Flex,
    Link,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import studentApi from 'core/api/student.api';
import { useQuery } from 'react-query';
import { ApplyModel } from '../companies/components/ApplyModel';

export default function Vacancies() {
    const { data: student } = useQuery('student-profile', studentApi.getStudent);

    const cvIdtoCV = (cvId: string) => {
        return student?.cvList.find((cv) => cv._id === cvId);
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }} px={3}>
            <Card>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>Company</Th>
                                <Th>Job Title [Applicants]</Th>
                                <Th>CV</Th>
                                <Th>CV Downloaded Time </Th>
                                <Th> </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {student?.applications?.map((app: any, index: number) => (
                                <Tr key={app._id}>
                                    <Td>{index + 1}</Td>
                                    <Td>{app.vacancyId.companyId.name}</Td>
                                    <Td>
                                        {app.vacancyId.jobTitle} [{app.vacancyId.applications}]
                                    </Td>
                                    <Td>
                                        <Link color={'blue'} href={cvIdtoCV(app.cvId)?.url} isExternal>
                                            <Flex alignItems={'center'} my={2}>
                                                <ExternalLinkIcon mx="2px" /> &nbsp; View CV
                                            </Flex>
                                        </Link>
                                    </Td>
                                    <Td>
                                        {app.lastDownloadAt ? (
                                            new Date(app.lastDownloadAt)
                                                .toLocaleString('en-GB', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })
                                                .toUpperCase()
                                        ) : (
                                            <Tag colorScheme={'gray'} variant="outline">
                                                Not Yet Downloaded
                                            </Tag>
                                        )}
                                    </Td>
                                    <Td>
                                        <ApplyModel company={{ _id: app.companyId } as any} vacancy={app.vacancyId} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                {student?.applications.length === 0 && (
                    <Alert status="warning" borderRadius="5px" justifyContent="center">
                        <AlertIcon />
                        <AlertDescription>No Applications. Please Apply to Vacancies</AlertDescription>
                    </Alert>
                )}
            </Card>
        </Box>
    );
}
