// Chakra Imports
import { Avatar, Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
// Assets
import { useAuth } from 'contexts/AuthContext';
import { useEffect, useState } from 'react';
import all_routes from 'routes';

export default function HeaderLinks(props: { secondary: boolean }) {
    const { secondary } = props;
    const auth = useAuth();
    const routes = all_routes.filter((route) => route.layout === '/' + auth.userType);
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');

    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );
    const [name, setName] = useState(null as any);
    const [logo, setLogo] = useState('');

    useEffect(() => {
        if (auth.userType === 'company') {
            setName(auth.company?.name);
            setLogo(auth.company?.logoURL);
        } else {
            setName(auth.user.email);
            setLogo(`https://api.dicebear.com/7.x/personas/svg?seed=${auth.user.email}&mouth=bigSmile,smile`);
        }
    }, [auth]);

    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            bg={menuBg}
            flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
            p="10px"
            borderRadius="30px"
            boxShadow={shadow}
        >
            <SidebarResponsive routes={routes} />
            <Flex alignItems="center">
                <Box mx={4}>{name ?? auth.userType}</Box>
                <Menu>
                    <MenuButton p="0px">
                        <Avatar
                            style={{ overflow: 'hidden' }}
                            _hover={{ cursor: 'pointer' }}
                            color="white"
                            name={name}
                            src={logo}
                            bg="#d1d4f9"
                            size="sm"
                            w="40px"
                            h="40px"
                        />
                    </MenuButton>
                    <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
                        <Flex w="100%" mb="0px">
                            <Text
                                ps="20px"
                                pt="16px"
                                pb="10px"
                                pr="15px"
                                w="100%"
                                borderBottom="1px solid"
                                borderColor={borderColor}
                                fontSize="sm"
                                fontWeight="700"
                                color={textColor}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                title={`👋 Hey, ${name ?? 'there'}`} // Tooltip for full name
                            >
                                👋&nbsp; Hey, {name ?? 'there'}
                            </Text>
                        </Flex>
                        <Flex flexDirection="column" p="10px">
                            <MenuItem
                                _hover={{ bg: 'none' }}
                                _focus={{ bg: 'none' }}
                                color="red.400"
                                borderRadius="8px"
                                px="14px"
                                onClick={() => auth.logout()}
                            >
                                <Text fontSize="sm">Log out</Text>
                            </MenuItem>
                        </Flex>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
};
