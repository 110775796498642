import React, { Fragment, useEffect, useState } from 'react';
// Chakra imports
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    Spacer,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import FileUploadModal from './FileUploadModal';

import { FaTrash } from 'react-icons/fa';

import { AppAlert } from 'contexts/AppAlert';
import profileApi from 'core/api/profile.api';
import { CVObject } from 'core/models/student.model';
import { useQueryClient } from 'react-query';

export default function CV(props: {
    isLoading: boolean;
    cvList: CVObject[];

    [x: string]: any;
}) {
    const { isLoading, cvList, refetchData, ...rest } = props;
    const uploadFileBtnRef = React.useRef();
    const toast = useToast();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const [selectedCV, setSelectedCV] = useState<CVObject>();

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const queryClient = useQueryClient();

    useEffect(() => {
        if (cvList.length > 0) setSelectedCV(cvList[0]);
    }, [cvList]);

    const deleteCV = async (id: string) => {
        try {
            await AppAlert.fire({
                title: `Are you sure you want to delete this CV?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f12b1e',
                cancelButtonColor: '#656565',
                confirmButtonText: 'Yes, Delete It!',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await profileApi.deleteCV(id);
                    toast({
                        title: 'Update successful',
                        description: 'Your resume was deleted successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    await queryClient.refetchQueries(['student-profile']);
                    if (selectedCV._id === id) {
                        setSelectedCV(null);
                    }
                }
            });
        } catch (error: any) {
            toast({
                title: 'Upload Failed',
                description: error?.response.data.message || "We couldn't delete the file. Please try again",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Card {...rest} mb="20px" p="20px">
            {isModalOpen ? (
                <FileUploadModal
                    isLoading={isLoading}
                    isOpen={isModalOpen}
                    btnRef={uploadFileBtnRef}
                    onClose={onModalClose}
                />
            ) : null}
            <Flex h={selectedCV ? '100%' : 'fit-content'} direction="column">
                <Flex direction="column" pt="10px">
                    <Flex direction="row">
                        <Text color={textColorPrimary} fontWeight="bold" textAlign="start" fontSize="2xl">
                            My Resumes
                        </Text>
                        <Spacer />
                        <Button as="span" variant="brand" ref={uploadFileBtnRef} onClick={onModalOpen}>
                            Upload File
                        </Button>
                    </Flex>

                    <Alert status="info" my="12px" borderRadius="10px">
                        <AlertIcon />
                        You can upload up to 5 files
                    </Alert>
                    <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr ', marginBottom: '1rem' }} mx="2rem">
                        {cvList.map((obj: CVObject, index) => (
                            <Fragment key={obj._id}>
                                <Text style={{}}>
                                    <label style={{ cursor: 'pointer' }}>
                                        <input
                                            type="radio"
                                            name="cv"
                                            id={obj._id}
                                            checked={selectedCV?._id === obj._id ? true : false}
                                            onChange={() => setSelectedCV({ ...obj })}
                                        />{' '}
                                        &nbsp;
                                        <b>{index + 1}.</b> {obj.cvName}{' '}
                                    </label>
                                </Text>
                                <Text>
                                    <Button
                                        leftIcon={<FaTrash />}
                                        ml="10px"
                                        variant={'ghost'}
                                        colorScheme={'red'}
                                        size={'sm'}
                                        onClick={(e) => deleteCV(obj._id)}
                                    >
                                        Delete
                                    </Button>
                                </Text>
                            </Fragment>
                        ))}
                    </Box>
                </Flex>
                {selectedCV && (
                    <object
                        data={selectedCV.url}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                        aria-label="cv"
                    ></object>
                )}
            </Flex>
        </Card>
    );
}
