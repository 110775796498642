import axios from 'axios';
import { baseUrl } from '../../constant/baseUrl';
import { SponsorshipModel } from '../models/sponsorship.model';

// TODO: Combine this with ReservationApi
export class SponsorshipApi {
    getSponsorshipList() {
        return axios.get(`${baseUrl}/sponsorships`);
    }

    updateSponsorship(sponsorship: SponsorshipModel) {}
}

const sponsorshipApiInstance = new SponsorshipApi();
export default sponsorshipApiInstance;
