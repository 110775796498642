// Enums for reservation status
import { Company } from './company.model';

export enum ReservationStatus {
    Cancelled = 'Cancelled',
    Waiting = 'Waiting',
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
}

export enum StallStatus {
    Available = 'available',
    Booked = 'pending',
    Allocated = 'allocated',
}

export enum UpdateReservationType {
    Accept = 'accept',
    Reject = 'reject',
}

export interface ReservationStall {
    documentID: string;
    stallID: string;
    status: StallStatus;
    price: number;
    size: StallSize;
    capacity: number;
}

export interface ReservationSponsorship {
    package: string;
    documentID: string;
    price: number;
    freeStallsCount: number;
    additionalRepCount: number;
}

export interface StallModel extends Omit<ReservationStall, 'documentID'> {
    _id: string;
    createdAt?: Date;
    updatedAt?: Date;
    allocatedCompany?: string | null;
}

// Frontend Reservation Model type
export interface ReservationModel {
    _id?: string; // Reservation ID
    company: string; // Reference to the company (ObjectId as a string)
    status: ReservationStatus; // Status of the reservation
    stalls: ReservationStall[]; // List of stalls in the reservation
    sponsorship?: ReservationSponsorship | null; // Sponsorship details (optional)
    paymentAmount?: number; // Total payment amount
    paymentProof?: string; // URL or path to the payment proof (optional)
    paymentRemarks?: string; // Remarks or notes about the payment (optional)
    createdAt?: string; // ISO string timestamp for creation time (optional)
    updatedAt?: string; // ISO string timestamp for last update time (optional)
}

export interface SubmitPaymentProofRequest {
    updatedAt: Date;
    reservationId: string;
    proofUrl: string;
    remarks?: string;
}

export enum StallSize {
    Small = '2m x 2m',
    Large = '3m x 3m',
}

export interface ReservationCreateOrUpdateRequest {
    company: string;
    status: ReservationStatus;
    paymentAmount: number;
    stalls: ReservationStall[];
}

export interface CompanyReservationsResponse {
    companyCount: number;
    approvedCount: number;
    pendingCount: number;
    waitingCount: number;
    reservations: ReservationModel[];
    companies: Company[];
}
