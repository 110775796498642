import { Company } from '../models/company.model';
import { StudentUpdate } from '../models/user.model';
import axios from 'axios';
import { baseUrl } from 'constant/baseUrl';

export class ProfileApi {
    getCompanyProfile() {
        return axios.get(`${baseUrl}/profile/company`);
    }

    updateCompanyProfile(company: Company) {
        return axios.patch(`${baseUrl}/profile/company`, company);
    }

    getUserProfile() {
        return axios.get(`${baseUrl}/student/profile`);
    }

    updateUserProfile(user: StudentUpdate) {
        return axios.post(`${baseUrl}/student/update`, user);
    }

    uploadCV(file: FormData) {
        return axios.post(`${baseUrl}/student/cv/upload`, file);
    }

    deleteCV(cvId: string) {
        return axios.delete(`${baseUrl}/student/cv/delete/` + cvId);
    }

    getCVList() {
        return axios.get(`${baseUrl}/student/cv/list/`);
    }

    getStallInfo(companyID: String) {
        return axios.get(`${baseUrl}/stalls/company/` + companyID);
    }

    uploadProfilePic(file: FormData) {
        return axios.post(`${baseUrl}/student/profile-pic/upload`, file);
    }
}

const profileApi = new ProfileApi();
export default profileApi;
