import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { AppAlert } from 'contexts/AppAlert';
import studentApi from 'core/api/student.api';
import vacancyApi from 'core/api/vacancy.api';
import { Company } from 'core/models/company.model';
import { CVObject } from 'core/models/student.model';
import { Vacancy } from 'core/models/vacancy.model';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export function ApplyModel({ company, vacancy }: { company?: Company; vacancy: Vacancy }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data: student } = useQuery('student-profile', studentApi.getStudent);
    const [selectedCV, setSelectedCV] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (student?.cvList.length > 0) setSelectedCV(student.cvList[0]);
    }, [student]);

    const handleApply = () => {
        setIsLoading(true);
        vacancyApi
            .candidateApplyVacancy({
                companyId: company._id,
                vacancyId: vacancy._id,
                cvId: selectedCV._id,
            })
            .finally(() => {
                setIsLoading(false);
                queryClient.invalidateQueries('student-profile');
                queryClient.invalidateQueries(['company', company._id]);
            });
    };

    const handleDelete = (applicationId: string) => {
        AppAlert.fire({
            title: `Are you sure ? \n Cancel Application to  \n "${vacancy.jobTitle}"`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f12b1e',
            cancelButtonColor: '#656565',
            confirmButtonText: 'Yes, Delete It!',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                vacancyApi.cancelApplication(applicationId).finally(() => {
                    setIsLoading(false);
                    queryClient.invalidateQueries('student-profile');
                    queryClient.invalidateQueries(['company', company._id]);
                });
            }
        });
    };

    if (!student) return null;

    const matchingApplication = student.applications.find((e) => e.vacancyId._id === vacancy._id);

    if (matchingApplication) {
        return (
            <Button
                colorScheme="red"
                variant="outline"
                size="sm"
                onClick={() => handleDelete(matchingApplication._id)}
                isLoading={isLoading}
            >
                Cancel Application
            </Button>
        );
    }

    return (
        <>
            {company ? (
                <Tooltip label={company.cvSubmitable ? '' : 'Currently Not Accepting CVs'} shouldWrapChildren={true}>
                    <Button
                        colorScheme="blue"
                        variant="outline"
                        size="sm"
                        onClick={onOpen}
                        title="Apply for this vacancy"
                        isDisabled={company.cvSubmitable === false}
                    >
                        Apply
                    </Button>
                </Tooltip>
            ) : null}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent py={3}>
                    <ModalHeader>Apply to "{vacancy.jobTitle}"</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={2}> Select CV :</Text>
                        <Box mx="2rem">
                            {student.cvList.map((obj: CVObject, index) => (
                                <Text key={obj._id}>
                                    <label style={{ cursor: 'pointer' }}>
                                        <input
                                            type="radio"
                                            name="cv"
                                            readOnly
                                            id={obj._id}
                                            checked={selectedCV?._id === obj._id ? true : false}
                                            onClick={() => setSelectedCV({ ...obj })}
                                        />{' '}
                                        &nbsp;
                                        <b>{index + 1}.</b> {obj.cvName}{' '}
                                    </label>
                                </Text>
                            ))}
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="green" disabled={!selectedCV} onClick={handleApply} isLoading={isLoading}>
                            Apply
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
