import { Box, Skeleton } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import companyApi from 'core/api/company.api';
import { CompanyWithoutVacancies } from 'core/models/company.model';
import { useEffect, useState } from 'react';
import CompanyTable from 'views/admin/default/components/CompanyTable';

export default function UserReports() {
    const auth = useAuth();
    const [companies, setCompanies] = useState<CompanyWithoutVacancies[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getCompanies = async () => {
        setLoading(true);
        try {
            const res = await companyApi.getCompanyList();
            setCompanies(res.data);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCompanies();
    }, [auth]);

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Skeleton isLoaded={!loading}>
                <CompanyTable tableData={companies} refreshData={getCompanies} />
            </Skeleton>
        </Box>
    );
}
