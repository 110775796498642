import {
    Avatar,
    Box,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Editable,
    EditableInput,
    EditablePreview,
    EditableTextarea,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Icon,
    Image,
    Input,
    SkeletonCircle,
    SkeletonText,
    Text,
    Textarea,
    Tooltip,
    useToast,
} from '@chakra-ui/react';
import EditableControls from 'components/editableControls/EditableControls';
import Dropzone from 'views/admin/profile/components/Dropzone';

import companyApi from 'core/api/company.api';

import fileApi from 'core/api/file.api';
import { CompanyUpdateRequest, CompanyWithoutVacancies } from 'core/models/company.model';
import { useEffect, useState } from 'react';
import { MdUpload } from 'react-icons/md';

type flyer = 'flyer01' | 'flyer02';

export default function EditCompanyDrawer(props: {
    data: CompanyWithoutVacancies;
    isOpen: boolean;
    btnRef: any;
    onClose: () => void;
}) {
    const { isOpen, btnRef, onClose, data } = props;
    const [company, setCompany] = useState<CompanyWithoutVacancies>();
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    useEffect(() => {
        setCompany(data);
    }, [data, props]);

    const onSubmit = async (data: CompanyUpdateRequest) => {
        setLoading(true);
        try {
            const res = await companyApi.updateCompany(company._id, data);
            setCompany(res);
            toast({
                title: 'Details Updated!',
                description: 'The company details were updated successfully',
                status: 'success',
                variant: 'solid',
                duration: 9000,
                isClosable: true,
            });
        } catch (err) {
            console.error(err);
            toast({
                title: 'Update Failed',
                description: "We couldn't update the details. Please try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    const updateLogo = async (file: File) => {
        setLoading(true);
        var payload = new FormData();
        payload.append('file', file);
        try {
            const res = await fileApi.uploadFile(payload);
            if (!res.data.url) throw Error;
            await onSubmit({ logoURL: res.data.url });
        } catch (err) {
            console.error(err);
            toast({
                title: 'Upload Failed',
                description: "We couldn't upload the file. Please try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    const updateFlyer = async (file: File, flyerType: flyer) => {
        setLoading(true);
        var payload = new FormData();
        payload.append('file', file);
        try {
            const res = await fileApi.uploadFile(payload);
            if (!res.data.url) throw Error;
            let updateRequest: CompanyUpdateRequest = {};
            if (flyerType === 'flyer01') updateRequest.flyer01 = res.data.url;
            else if (flyerType === 'flyer02') updateRequest.flyer02 = res.data.url;
            await onSubmit(updateRequest);
        } catch (err) {
            console.error(err);
            toast({
                title: 'Upload Failed',
                description: "We couldn't upload the file. Please try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader paddingTop="50px">Edit {company?.name ?? ''} details</DrawerHeader>
                <Divider />
                <DrawerBody>
                    {company && !loading ? (
                        <Flex
                            zIndex="2"
                            direction="column"
                            maxW="100%"
                            background="transparent"
                            borderRadius="15px"
                            mx={{ base: 'auto', lg: 'unset' }}
                            me="auto"
                            mb={{ base: '20px', md: '20px' }}
                            gap="6"
                        >
                            {company.video && (
                                <Box borderRadius="16px" h="131px" w="100%">
                                    <iframe
                                        src={company.video}
                                        style={{
                                            width: '100%',
                                            height: '131px',
                                            border: '1px solid lightgray',
                                            borderRadius: '16px',
                                        }}
                                        allow="autoplay"
                                        title="company video"
                                    ></iframe>
                                </Box>
                            )}

                            <Avatar mx="auto" src={company.logoURL} h="87px" w="87px" mt="-43px" border="1px solid" />

                            {company.flyer01 || company.flyer02 ? (
                                <Flex justifyContent="space-around" width="100%" marginX="10px">
                                    {company.flyer01 && <Image src={company.flyer01} height="150px" />}
                                    {company.flyer02 && <Image src={company.flyer02} height="150px" />}
                                </Flex>
                            ) : (
                                ''
                            )}

                            <Dropzone
                                bg="gray.50"
                                onSave={updateLogo}
                                content={
                                    <Box>
                                        <Icon as={MdUpload} w="80px" h="80px" color="secondaryGray.700" />
                                        <Flex justify="center" mx="auto" mb="12px">
                                            <Text fontSize="lg" fontWeight="700" color="secondaryGray.700">
                                                Upload Logo
                                            </Text>
                                        </Flex>
                                    </Box>
                                }
                            />

                            <Flex justifyContent="space-evenly">
                                <Dropzone
                                    bg="gray.50"
                                    onSave={(file) => updateFlyer(file, 'flyer01')}
                                    content={
                                        <Box>
                                            <Icon as={MdUpload} w="80px" h="80px" color="secondaryGray.700" />
                                            <Flex justify="center" mx="auto" mb="12px">
                                                <Text fontSize="lg" fontWeight="700" color="secondaryGray.700">
                                                    Upload Flyer 01
                                                </Text>
                                            </Flex>
                                        </Box>
                                    }
                                />

                                <Dropzone
                                    bg="gray.50"
                                    onSave={(file) => updateFlyer(file, 'flyer02')}
                                    content={
                                        <Box>
                                            <Icon as={MdUpload} w="80px" h="80px" color="secondaryGray.700" />
                                            <Flex justify="center" mx="auto" mb="12px">
                                                <Text fontSize="lg" fontWeight="700" color="secondaryGray.700">
                                                    Upload Flyer 02
                                                </Text>
                                            </Flex>
                                        </Box>
                                    }
                                />
                            </Flex>

                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Company Video URL
                                </FormLabel>
                                <Editable
                                    defaultValue={company.video ?? 'Company Video URL'}
                                    isPreviewFocusable={true}
                                    onSubmit={(value) => onSubmit({ video: value })}
                                    submitOnBlur={false}
                                    onChange={(value) => setCompany({ ...company, video: value })}
                                >
                                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                                        <Input
                                            py={2}
                                            px={4}
                                            overflow="hidden"
                                            as={EditablePreview}
                                            data-lpignore="true"
                                        />
                                    </Tooltip>
                                    <Input py={2} px={4} as={EditableInput} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>

                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Company Name
                                </FormLabel>
                                <Editable
                                    defaultValue={company.name ?? 'Company Name'}
                                    isPreviewFocusable={true}
                                    selectAllOnFocus={false}
                                    onSubmit={(value) => onSubmit({ name: value })}
                                    submitOnBlur={false}
                                    onChange={(value) => setCompany({ ...company, name: value })}
                                >
                                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                                        <Input py={2} px={4} as={EditablePreview} data-lpignore="true" />
                                    </Tooltip>
                                    <Input py={2} px={4} as={EditableInput} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>

                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Company Address
                                </FormLabel>
                                <Editable
                                    defaultValue={company.address ?? 'Company Address'}
                                    isPreviewFocusable={true}
                                    selectAllOnFocus={false}
                                    onSubmit={(value) => onSubmit({ address: value })}
                                    onChange={(value) => setCompany({ ...company, address: value })}
                                    submitOnBlur={false}
                                >
                                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                                        <Input py={2} px={4} as={EditablePreview} />
                                    </Tooltip>
                                    <Input py={2} px={4} as={EditableInput} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>

                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Company Email
                                </FormLabel>
                                <Editable
                                    defaultValue={company.email ?? 'Company Email'}
                                    isPreviewFocusable={true}
                                    selectAllOnFocus={false}
                                    onSubmit={(value) => onSubmit({ email: value })}
                                    onChange={(value) => setCompany({ ...company, email: value })}
                                    submitOnBlur={false}
                                >
                                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                                        <Input py={2} px={4} as={EditablePreview} />
                                    </Tooltip>
                                    <Input py={2} px={4} as={EditableInput} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>

                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Contact Person Name
                                </FormLabel>
                                <Editable
                                    defaultValue={company.contactPerson ?? 'Contact Person'}
                                    isPreviewFocusable={true}
                                    selectAllOnFocus={false}
                                    onSubmit={(value) => onSubmit({ contactPerson: value })}
                                    onChange={(value) => setCompany({ ...company, contactPerson: value })}
                                    submitOnBlur={false}
                                >
                                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                                        <Input py={2} px={4} as={EditablePreview} />
                                    </Tooltip>
                                    <Input py={2} px={4} as={EditableInput} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>
                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Contact Person Number
                                </FormLabel>
                                <Editable
                                    defaultValue={company.contactNo ?? 'Contact Number'}
                                    isPreviewFocusable={true}
                                    selectAllOnFocus={false}
                                    onSubmit={(value) => onSubmit({ contactNo: value })}
                                    onChange={(value) => setCompany({ ...company, contactNo: value })}
                                    submitOnBlur={false}
                                >
                                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                                        <Input py={2} px={4} as={EditablePreview} />
                                    </Tooltip>
                                    <Input py={2} px={4} as={EditableInput} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>
                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Company Description
                                </FormLabel>
                                <Editable
                                    defaultValue={
                                        company.description.length > 0 ? company.description : 'Company Description'
                                    }
                                    isPreviewFocusable={true}
                                    selectAllOnFocus={false}
                                    onSubmit={(value) => onSubmit({ description: value })}
                                    onChange={(value) => setCompany({ ...company, description: value })}
                                    submitOnBlur={false}
                                >
                                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                                        <EditablePreview
                                            py={2}
                                            px={4}
                                            border="1px solid"
                                            borderColor="secondaryGray.100"
                                            width="100%"
                                        />
                                    </Tooltip>
                                    <Textarea py={2} px={4} as={EditableTextarea} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>
                            <FormControl>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
                                    Company Wesbite URL
                                </FormLabel>
                                <Editable
                                    defaultValue={company.webURL ?? 'Company Website URL'}
                                    isPreviewFocusable={true}
                                    selectAllOnFocus={false}
                                    onSubmit={(value) => onSubmit({ webURL: value })}
                                    onChange={(value) => setCompany({ ...company, webURL: value })}
                                    submitOnBlur={false}
                                >
                                    <Tooltip label="Click to edit">
                                        <Input py={2} px={4} as={EditablePreview} />
                                    </Tooltip>
                                    <Input py={2} px={4} as={EditableInput} />
                                    <EditableControls />
                                </Editable>
                            </FormControl>
                        </Flex>
                    ) : (
                        <Box padding="6" boxShadow="lg" bg="white">
                            <SkeletonCircle size="10" />
                            <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
                        </Box>
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
