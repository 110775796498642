import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    HStack,
    Image,
    Input,
    Select,
    Spacer,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import reservationApi from 'core/api/reservation.api';
import { CompanyStallFilters } from 'core/models/company-stall.model';
import { ReservationModel, ReservationStatus, UpdateReservationType } from 'core/models/reservation.model';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
export interface AdminStallManagementProps {}

const initialFilters: CompanyStallFilters = {
    name: '',
    status: 'all',
};

export default function Stalls() {
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

    const [stats, setStats] = useState([
        {
            label: 'Total Companies',
            value: 0,
        },
        {
            label: 'Allocated Slots',
            value: 0,
        },
        {
            label: 'Waiting Slots', // wait for accept
            value: 0,
        },
        {
            label: 'Pending Slots', // not yet paid or submit proof
            value: 0,
        },
    ]);

    const [shouldRefresh, setShouldRefresh] = useState(false);

    const [companyReservations, setCompanyReservations] = useState({
        companyReservationsList: [] as ReservationModel[],
        initList: [] as ReservationModel[],
    });

    const [companies, setCompanies] = useState([]);

    const [filterFormValues, setFilterFormValues] = useState({ ...initialFilters });

    const handleFilterFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterFormValues({
            ...filterFormValues,
            [event.target.name]: event.target.value,
        });
    };
    const updateReservationStatus = (
        reservationId: string,
        name: string,
        status: string,
        type: UpdateReservationType
    ) => {
        Swal.fire({
            title: `Are you sure you want to ${type} the ${status} reservation of ${name}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f12b1e',
            cancelButtonColor: '#656565',
            confirmButtonText: 'Yes, change it!',
        }).then((results) => {
            if (results.isConfirmed) {
                reservationApi.acceptOrRejectReservation(reservationId, type).then(
                    (res) => {
                        setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                        Swal.fire('Updated!', 'Company has been updated.', 'success');
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        });
    };

    //fetch the companies with stalls
    useEffect(() => {
        reservationApi.getCompanyReservationsList().then(
            (res) => {
                console.log(res.data);
                setStats([
                    {
                        label: 'Total Companies',
                        // group by companies
                        value: res.data.companyCount ?? 0,
                    },
                    {
                        label: 'Approved Reservations',
                        value: res.data.approvedCount ?? 0,
                    },
                    {
                        label: 'Pending Reservations',
                        // reserved but not paid or proof uploaded
                        value: res.data.waitingCount ?? 0,
                    },
                    {
                        label: 'Waiting Reservations',
                        // waiting to get approved
                        value: res.data.pendingCount ?? 0,
                    },
                ]);
                setCompanyReservations((prev) => ({
                    ...prev,
                    companyReservationsList: res.data.reservations ?? [],
                    initList: res.data.reservations ?? [],
                }));
                setCompanies(res.data.companies);
            },
            (error) => {
                console.log(`${error}: when fetch company reservations`);
            }
        );
    }, [shouldRefresh]);

    const onSearchWithParams = () => {
        if (filterFormValues.name !== '' && filterFormValues.status !== '') {
            const filterData = companyReservations.initList.filter((reservation) => {
                return 'companyName'.toLowerCase().includes(filterFormValues.name.toLowerCase());
            });
            if (filterFormValues.status === 'all') {
                setCompanyReservations({ ...companyReservations, companyReservationsList: filterData });
            } else {
                const filterDataWithStatus = filterData.filter((reservation) => {
                    return reservation.status === filterFormValues.status;
                });
                setCompanyReservations({ ...companyReservations, companyReservationsList: filterDataWithStatus });
            }
        } else if (filterFormValues.name === '' && filterFormValues.status !== 'all') {
            const filterDataWithStatus = companyReservations.initList.filter((reservation) => {
                return reservation.status === filterFormValues.status;
            });
            setCompanyReservations({ ...companyReservations, companyReservationsList: filterDataWithStatus });
        } else if (filterFormValues.name === '' && filterFormValues.status === 'all') {
            setCompanyReservations({ ...companyReservations, companyReservationsList: companyReservations.initList });
        }
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid gap={6} mb={6} gridTemplateColumns="1fr 1fr 1fr 1fr">
                {stats.map((stat) => (
                    <Card key={stat.label} py={3}>
                        <Stat>
                            <StatLabel>{stat.label}</StatLabel>
                            <StatNumber>{stat.value}</StatNumber>
                        </Stat>
                    </Card>
                ))}
            </Grid>

            <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <HStack mx={6} mb={6} alignItems="end">
                    <FormControl size="small">
                        <FormLabel id="stall-status-label">Company Name </FormLabel>
                        <Input
                            id="name"
                            name="name"
                            placeholder="Search Companies"
                            size="sm"
                            onChange={handleFilterFormChange}
                        />
                    </FormControl>
                    <Spacer flex={' 1 1 0'} />
                    <FormControl size="small">
                        <FormLabel id="stall-status-label">Stall Status</FormLabel>
                        <Select
                            id="status"
                            name="status"
                            size="sm"
                            value={filterFormValues.status}
                            onChange={(event) =>
                                setFilterFormValues({
                                    ...filterFormValues,
                                    [event.target.name || 'name_missing']: event.target.value,
                                })
                            }
                        >
                            <option value={'all'}>All</option>
                            <option value={'allocated'}>Allocated</option>
                            <option value={'pending'}>Pending</option>
                        </Select>
                    </FormControl>
                    <Button
                        variant="outline"
                        size="sm"
                        colorScheme={'teal'}
                        px={8}
                        onClick={() => {
                            onSearchWithParams();
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        px={8}
                        onClick={() => {
                            setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                            setFilterFormValues({ ...initialFilters });
                        }}
                    >
                        Reset
                    </Button>
                </HStack>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th borderColor={borderColor} align="left">
                                    #
                                </Th>
                                <Th borderColor={borderColor} align="left"></Th>
                                <Th borderColor={borderColor} align="left">
                                    Name
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Allocated Slots
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Amount (Rs)
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Status
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Proof
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Action
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {companyReservations.companyReservationsList
                                .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1))
                                .map((companyReservation, index) => {
                                    const company = companies.find(
                                        (company) => company._id === companyReservation.company
                                    );
                                    return (
                                        <Tr key={companyReservation._id}>
                                            <Td>{index + 1}</Td>
                                            <Td>
                                                <Image
                                                    src={company?.logoURL ?? 'logo.png'}
                                                    alt={'Logo'}
                                                    maxHeight="2rem"
                                                    maxWidth={'3rem'}
                                                />
                                            </Td>
                                            <Td>{company?.name ?? 'Company Name'}</Td>
                                            <Td>
                                                <HStack spacing={2}>
                                                    {companyReservation.stalls.map((stall, index) => (
                                                        <Tag size={'md'} key={index} variant="solid" colorScheme="teal">
                                                            {stall.stallID}
                                                        </Tag>
                                                    ))}
                                                </HStack>
                                            </Td>
                                            <Td align="left">
                                                <HStack direction="row" spacing={1} alignItems="left">
                                                    {
                                                        <Tag size="md" variant="subtle" colorScheme="error">
                                                            {companyReservation.paymentAmount}
                                                        </Tag>
                                                    }
                                                </HStack>
                                            </Td>
                                            <Td align="left">
                                                <HStack direction="row" spacing={1} alignItems="left">
                                                    {companyReservation.status === ReservationStatus.Pending && (
                                                        <Tag
                                                            size="md"
                                                            variant="subtle"
                                                            colorScheme={
                                                                companyReservation.status === ReservationStatus.Pending
                                                                    ? 'orange'
                                                                    : 'error'
                                                            }
                                                        >
                                                            Waiting for approval
                                                        </Tag>
                                                    )}
                                                    {companyReservation.status === ReservationStatus.Waiting && (
                                                        <Tag
                                                            size="md"
                                                            variant="subtle"
                                                            colorScheme={
                                                                companyReservation.status === ReservationStatus.Waiting
                                                                    ? 'orange'
                                                                    : 'error'
                                                            }
                                                        >
                                                            Pending (proof)
                                                        </Tag>
                                                    )}
                                                    {companyReservation.status === ReservationStatus.Approved && (
                                                        <Tag
                                                            size="md"
                                                            variant="subtle"
                                                            colorScheme={
                                                                companyReservation.status === ReservationStatus.Approved
                                                                    ? 'info'
                                                                    : 'error'
                                                            }
                                                        >
                                                            Allocated
                                                        </Tag>
                                                    )}
                                                </HStack>
                                            </Td>
                                            <Td align="left">
                                                <HStack direction="row" spacing={1} alignItems="left">
                                                    {(companyReservation.paymentProof === undefined ||
                                                        companyReservation.paymentProof === null) && (
                                                        <Tag size="md" variant="subtle" colorScheme="error">
                                                            N/A
                                                        </Tag>
                                                    )}
                                                    {companyReservation.paymentProof && (
                                                        <Tag size="md" variant="subtle" colorScheme="info">
                                                            <a
                                                                href={companyReservation.paymentProof}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                proof Url
                                                            </a>
                                                        </Tag>
                                                    )}
                                                </HStack>
                                            </Td>
                                            <Td align="left">
                                                <HStack direction="row" spacing={3} alignItems="right">
                                                    <Button
                                                        disabled={
                                                            companyReservation.status !== ReservationStatus.Pending ||
                                                            companyReservation.paymentProof === undefined ||
                                                            companyReservation.paymentProof === null
                                                        }
                                                        size="sm"
                                                        color="green.500"
                                                        variant="outline"
                                                        onClick={() => {
                                                            updateReservationStatus(
                                                                companyReservation._id,
                                                                company?.name ?? 'Company Name',
                                                                companyReservation.status,
                                                                UpdateReservationType.Accept
                                                            );
                                                        }}
                                                    >
                                                        {/* <Edit size={16} /> */}
                                                        Approve
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            companyReservation.status === ReservationStatus.Approved
                                                        }
                                                        size="sm"
                                                        color="red"
                                                        variant="outline"
                                                        onClick={() => {
                                                            updateReservationStatus(
                                                                companyReservation._id,
                                                                company?.name ?? 'Company Name',
                                                                companyReservation.status,
                                                                UpdateReservationType.Reject
                                                            );
                                                        }}
                                                    >
                                                        {/* <Edit size={16} /> */}
                                                        Reject
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            companyReservation.status !== ReservationStatus.Approved
                                                        }
                                                        size="sm"
                                                        color="blue"
                                                        variant="outline"
                                                        onClick={() => {
                                                            updateReservationStatus(
                                                                companyReservation._id,
                                                                company?.name ?? 'Company Name',
                                                                companyReservation.status,
                                                                UpdateReservationType.Reject
                                                            );
                                                        }}
                                                    >
                                                        {/* <Edit size={16} /> */}
                                                        Put on Wait
                                                    </Button>
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
}
