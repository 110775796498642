import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Added by Raveesha - Test Redux
import jwt from 'jwt-decode';
// End Test

// Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyDXXZ_wDR1bwHUFoXLZhDUF6DwvUoRNpYU',
    authDomain: 'ucsc-career-fair.firebaseapp.com',
    projectId: 'ucsc-career-fair',
    storageBucket: 'ucsc-career-fair.appspot.com',
    messagingSenderId: '740903006631',
    appId: '1:740903006631:web:d9bb891fa6872915940682',
    measurementId: 'G-7MD53C18LL',
};

// Firebase Initialization
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Login
const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const token = await user.getIdToken();
        // @ts-ignore
        const user_decode: any = jwt(token);
        console.log(user_decode);
        // End Test
        localStorage.setItem('API-AUTH-TOKEN', token);
        localStorage.setItem('userId', user_decode.user_id);

        // if (user_decode.role === "admin") {
        // } else if (user_decode.role !== '') {
        //     localStorage.setItem("userId", user_decode.userId);
        // }

        console.log(user_decode.role);

        return { success: true, userType: user_decode.role, user: user_decode, token: token };
    } catch (error) {
        // console.error("Auth Error: ", error);
        return { success: false, errorMessage: error };
    }
};

const checkPassword = async (email: string, password: string) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        if (user) {
            return { success: true };
        } else {
            return { success: false };
        }
    } catch (error) {
        // console.error("Auth Error: ", error);
        return { success: false, errorMessage: error };
    }
};

// Logout
const logout = () => {
    localStorage.removeItem('API-AUTH-TOKEN');
    localStorage.removeItem('userId');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('role');

    return auth.signOut();

    // End Test
};

export { auth, loginWithEmailAndPassword, logout, checkPassword };
