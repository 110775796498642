import Swal from 'sweetalert2';
export const AppAlert = Swal.mixin({
    showClass: {
        popup: 'animate__animated animate__zoomInIn animate__faster',
        backdrop: 'swal2-backdrop-show',
        icon: 'swal2-icon-show',
    },
    color: '#000',
    cancelButtonColor: '#f12b1e',
});
