import { Box, Button, Heading, HStack, Spacer, Tag } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import { MdDeleteOutline } from 'react-icons/md';
import { formatCurrency, getAvailability } from '../index';

type Props = {
    id?: string;
    title?: string;
    subTitle?: string;
    status?: string;
    timeStamp?: string;
    price?: number;
    onDelete?: () => void;
};

const Stall = ({
    id = 'Z0',
    title = 'Stall Title',
    subTitle = 'Stall Sub Title',
    status = 'Available',
    timeStamp = '2023/00/00 00:00:00',
    price = 0.0,
    onDelete = () => {
        alert(`Clicked on delete!`);
    },
}: Props) => {
    return (
        <>
            <Box my={0} display="flex" alignItems={'center'}>
                <Heading size={'lg'} p="3">
                    {id}{' '}
                </Heading>

                <Box p={3}>
                    <h4>
                        {title} <small>{subTitle}</small>
                    </h4>
                    <p>
                        Status:{' '}
                        <Tag colorScheme={status === 'available' ? 'green' : 'red'}>{getAvailability(status)}</Tag>
                    </p>
                    {/*<p>{timeStamp}</p>*/}
                </Box>
                <Spacer />
                <HStack>
                    <Heading size="sm">
                        <span style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                            {formatCurrency(price)}
                            {price === 0 && (
                                <Tag size={'sm'} colorScheme="green">
                                    FREE
                                </Tag>
                            )}
                        </span>
                    </Heading>

                    <Button onClick={() => onDelete()} size={'sm'} title={'Remove stall'}>
                        <MdDeleteOutline size={21} color={'grey'} />
                        {/*&nbsp; Delete*/}
                    </Button>
                </HStack>
            </Box>
            <HSeparator />
        </>
    );
};

export default Stall;
