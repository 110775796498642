import { Route, Switch } from 'react-router-dom';
import { CompaniesList } from './components/CompanyList';
import { CompanyPage } from './components/CompanyPage';

export default function Test() {
    return (
        <Switch>
            <Route path={'/student/companies/:companyId'} component={CompanyPage} key={'vacancy-profile'} />
            <Route path={'/student/companies'} component={CompaniesList} key={'vacancy-profile'} />

            <Route path={'/company/companies/:companyId'} component={CompanyPage} key={'vacancy-profile'} />
            <Route path={'/company/companies'} component={CompaniesList} key={'vacancy-profile'} />

            <Route path={'/admin/companies/:companyId'} component={CompanyPage} key={'vacancy-profile'} />
            <Route path={'/admin/companies'} component={CompaniesList} key={'vacancy-profile'} />
        </Switch>
    );
}
