import { useEffect, useState } from 'react';
import { Avatar, Box, Button, IconButton, Spinner, Tooltip, useDisclosure, useToast, Flex } from '@chakra-ui/react';
import { MdModeEdit } from 'react-icons/md';
import profileApi from 'core/api/profile.api';

export default function ProfilePicture(props: {
    image: string;
    name: string;
    refetchData: () => Promise<void>;
    [x: string]: any;
}) {
    const { image, name, refetchData, ...rest } = { ...props };
    const { onOpen, onClose } = useDisclosure();
    const [isLoading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [selectedFile, setSelectedFile] = useState({ name: null, file: null });
    const [previewUrl, setPreviewUrl] = useState(null);
    const toast = useToast();

    const handleEditClick = () => {
        onOpen();
        setIsEditing(true);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            var payload = new FormData();
            payload.append('file', selectedFile.file);
            const res = await profileApi.uploadProfilePic(payload);
            if (!res.data.url) throw Error;
            toast({
                title: 'Upload successful',
                description: 'Your profile picture was updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            await refetchData();
        } catch (error) {
            console.log(error);
            toast({
                title: 'Upload Failed',
                description: "We couldn't upload the file. Please try again",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        setPreviewUrl(null);
        onClose();
        setIsEditing(false);
        setLoading(false);
    };

    const handleCancel = () => {
        setPreviewUrl(null);
        setIsEditing(false);
        onClose();
    };

    const handleImageChange = (e: any) => {
        console.log(e);
        const file = e.target.files[0];
        e.preventDefault();
        setSelectedFile({ name: 'logo', file: file });

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (isEditing) document.getElementById('fileInput').click();
    }, [isEditing]);

    return (
        <>
            <Box position="relative" display="inline-block" {...rest}>
                <Tooltip label="Edit Profile Picture" hasArrow>
                    <Avatar
                        boxSize="100px"
                        showBorder={false}
                        sx={{
                            img: {
                                height: '100px',
                                minWidth: '100px',
                                minHeight: '100px',
                            },
                        }}
                        name={name}
                        src={previewUrl ?? image}
                        _hover={{ cursor: 'pointer' }}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        onClick={() => {
                            setIsEditing(true);
                            if (isEditing) document.getElementById('fileInput').click();
                        }}
                    >
                        <Box
                            position="absolute"
                            inset={0}
                            bg="rgba(0,0,0,0.5)"
                            borderColor="transparent"
                            opacity={isEditing || isHovering ? 1 : 0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            transition="opacity 0.3s"
                            borderRadius="50%"
                        >
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <IconButton
                                    icon={<MdModeEdit />}
                                    colorScheme="white"
                                    onClick={handleEditClick}
                                    aria-label="Edit Image"
                                    size="xl"
                                />
                            )}
                        </Box>
                    </Avatar>
                </Tooltip>
            </Box>
            {isEditing && (
                <Flex mt={4} direction="column" alignItems="center" alignContent="center">
                    <input
                        type="file"
                        id="fileInput"
                        accept=".jpg, .jpeg, .png, .webp, image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                    <Button mb={2} variant="brand" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="action" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Flex>
            )}
        </>
    );
}
