import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { ButtonGroup, IconButton, useEditableControls } from '@chakra-ui/react';

export default function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps } = useEditableControls();

    return isEditing ? (
        <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
            <IconButton aria-label="save" icon={<CheckIcon />} {...getSubmitButtonProps()} />
            <IconButton aria-label="close" icon={<CloseIcon boxSize={3} />} {...getCancelButtonProps()} />
        </ButtonGroup>
    ) : null;
}
