/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { MdLock } from 'react-icons/md';
import companyApi from '../../../core/api/company.api';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useCompanyRoutes from '../../../hooks/useCompanyRoutes';

export function SidebarLinks(props: { routes: RoutesType[] }) {
    //   Chakra color mode
    let location = useLocation();
    let activeColor = useColorModeValue('gray.700', 'white');
    let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
    let activeIcon = useColorModeValue('brand.500', 'white');
    let textColor = useColorModeValue('secondaryGray.500', 'white');
    let brandColor = useColorModeValue('brand.500', 'brand.400');
    const { routes } = props;

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: string) => {
        return location.pathname.includes(routeName);
    };

    const { finalRoutes } = useCompanyRoutes(routes);

    // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
    const createLinks = (routes: RoutesType[]) => {
        return finalRoutes.map((route: RoutesType, index: number) => {
            if (
                route.layout === '/admin' ||
                route.layout === '/auth' ||
                route.layout === '/rtl' ||
                route.layout === '/company' ||
                route.layout === '/student'
            ) {
                return (
                    <NavLink
                        key={index}
                        to={route.isLocked ? '#' : route.layout + route.path}
                        onClick={(e) => route.isLocked && e.preventDefault()}
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            cursor: route.isLocked ? 'default' : 'pointer',
                            opacity: route.isLocked ? 0.5 : 1,
                        }}
                    >
                        {route.icon ? (
                            <Box>
                                <HStack
                                    spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
                                    py="5px"
                                    ps="10px"
                                >
                                    <Flex w="100%" alignItems="center" justifyContent="center">
                                        <Box
                                            color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                                            me="18px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {route.icon}
                                        </Box>
                                        <Text
                                            me="auto"
                                            color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                                            fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                                        >
                                            {route.name}
                                        </Text>
                                        {route.isLocked && (
                                            <Box
                                                color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                                                me="18px"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Icon as={MdLock} width="20px" height="20px" color="inherit" />
                                            </Box>
                                        )}
                                    </Flex>
                                    <Box
                                        h="36px"
                                        w="4px"
                                        bg={activeRoute(route.path.toLowerCase()) ? brandColor : 'transparent'}
                                        borderRadius="5px"
                                    />
                                </HStack>
                            </Box>
                        ) : (
                            <Box>
                                <HStack
                                    spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
                                    py="5px"
                                    ps="10px"
                                >
                                    <Text
                                        me="auto"
                                        color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                                        fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                                    >
                                        {route.name}
                                    </Text>
                                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                                </HStack>
                            </Box>
                        )}
                    </NavLink>
                );
            }
        });
    };
    //  BRAND
    return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
