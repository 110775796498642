/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Collapse,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Heading,
    HStack,
    Icon,
    Input,
    Skeleton,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast,
    VStack,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useAuth } from 'contexts/AuthContext';
import companyApi from 'core/api/company.api';
import fileApi from 'core/api/file.api';
import profileApi from 'core/api/profile.api';
import { Company } from 'core/models/company.model';
import {
    ReservationCreateOrUpdateRequest,
    ReservationModel,
    ReservationSponsorship,
    ReservationStall,
    ReservationStatus,
    StallModel,
    StallSize,
    SubmitPaymentProofRequest,
} from 'core/models/reservation.model';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { MdFilePresent, MdInfoOutline, MdUpload } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { HSeparator } from '../../../components/separator/Separator';
import reservationApi from '../../../core/api/reservation.api';
import stallApi from '../../../core/api/stall.api';
import Stall from './components/Stall';
import { STALL_INFO, StallMapNew } from './components/StallMapNew';
import CustomAlertDialog from '../../../components/alertDialog/CustomAlertDialog';

export const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'LKR',
        minimumFractionDigits: 2,
    }).format(value);
};

export const getAvailability = (availability: string) => {
    switch (availability) {
        case 'available':
            return 'Available';
        case 'pending':
            return 'Booked';
        case 'allocated':
            return 'Occupied';
        default:
            return availability;
    }
};

function StallBooking() {
    const auth = useAuth();
    const [props, setProps] = useState(auth);

    useEffect(() => {
        setProps(auth);
    }, [auth]);

    const { register, handleSubmit } = useForm({ reValidateMode: 'onBlur' });
    const [loading, setLoading] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [stallDataLoading, setStallDataLoading] = useState(true);
    const [reservationDataLoading, setReservationDataLoading] = useState(false);
    const [proofUrl, setProofUrl] = useState<string>('');
    const [company, setCompany] = useState<Company>(null);

    const [companies, setCompanies] = useState<Company[]>([]);
    const [companyId, setCompanyId] = useState('');
    const [currentStage, setCurrentStage] = useState(null);
    const [reservations, setReservations] = useState<ReservationModel[]>([]);
    const [stallData, setStallData] = useState<StallModel[]>([]);

    const history = useHistory();
    const bg = useColorModeValue('gray.100', 'navy.700');
    const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
    const toast = useToast();
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [activeDialog, setActiveDialog] = useState<string | null>(null);

    const openDialog = (dialogKey: string) => {
        setActiveDialog(dialogKey);
    };

    const closeDialog = () => {
        setActiveDialog(null);
    };

    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: ['.pdf'],
        maxFiles: 1,
    });

    const files = acceptedFiles.map((file) => (
        <Text fontSize="xs" key={`${file.name}${file.size}`} color="secondaryGray.700">
            {file.name}
        </Text>
    ));

    const selectedCompany = companies.find((x) => x._id === companyId);

    const [showMarkings, setShowMarkings] = useState(true);
    const [selectedStalls, setSelectedStalls] = useState<ReservationStall[]>([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [reservationId, setReservationId] = useState('');
    const [reservationStatus, setReservationStatus] = useState('');
    const [approvedReservations, setApprovedReservations] = useState<ReservationModel[]>([]);
    const [showGuideLines, setShowGuideLines] = useState(false);
    const [activeReservation, setActiveReservation] = useState<ReservationModel | null>(null);

    // const approvedReservationsWithSizeCounts = approvedReservations
    //     .map((reservation: ReservationModel) => {
    //         const sizeCounts = reservation.stalls.reduce(
    //             (acc: any, stall: ReservationStall) => {
    //                 const size = stall.size;
    //                 if (acc[size]) {
    //                     acc[size] += 1;
    //                 } else {
    //                     acc[size] = 1;
    //                 }
    //                 return acc;
    //             },
    //             {
    //                 '2m x 2m': 0,
    //                 '3m x 3m': 0,
    //             }
    //         );
    //
    //         const twoByTwoStalls =
    //             sizeCounts['2m x 2m'] > 0
    //                 ? `${sizeCounts['2m x 2m']} 2m x 2m Stall` + (sizeCounts['2m x 2m'] > 1 ? 's' : '')
    //                 : undefined;
    //         const threeByThreeStalls =
    //             sizeCounts['3m x 3m'] > 0
    //                 ? `${sizeCounts['3m x 3m']} 3m x 3m Stall` + (sizeCounts['3m x 3m'] > 1 ? 's' : '')
    //                 : undefined;
    //
    //         const sizeCountsString = [twoByTwoStalls, threeByThreeStalls].filter(Boolean).join(' and ');
    //
    //         const totalCapacity = sizeCounts['2m x 2m'] * 2 + sizeCounts['3m x 3m'] * 5;
    //
    //         return { sizeCountsString, sizeCounts, totalCapacity, ...reservation };
    //     })
    //     .sort((a, b) => (new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1));
    //
    // const stallCountSummary = approvedReservationsWithSizeCounts.reduce(
    //     (acc, reservation) => {
    //         return {
    //             ...acc,
    //             twoByTwoStalls: acc.twoByTwoStalls + reservation.sizeCounts['2m x 2m'],
    //             threeByThreeStalls: acc.threeByThreeStalls + reservation.sizeCounts['3m x 3m'],
    //         };
    //     },
    //     { twoByTwoStalls: 0, threeByThreeStalls: 0 }
    // );
    //
    // const stallCountSummaryString = [stallCountSummary.twoByTwoStalls, stallCountSummary.threeByThreeStalls]
    //     .filter(Boolean)
    //     .map((count, i) => `${count} ${i === 0 ? '2m x 2m' : '3m x 3m'} Stall${count > 1 ? 's' : ''}`)
    //     .join(' and ');

    const approvedReservationsWithSizeCounts = approvedReservations
        .map((reservation: ReservationModel) => {
            const sizeCounts = reservation.stalls.reduce(
                (acc: Record<string, number>, stall: ReservationStall) => {
                    const size = stall.size;
                    acc[size] = (acc[size] || 0) + 1;
                    return acc;
                },
                {} // Start with an empty object to dynamically count all stall sizes
            );

            const threeByThreeStalls = sizeCounts['3m x 3m']
                ? `${sizeCounts['3m x 3m']} 3m x 3m Stall` + (sizeCounts['3m x 3m'] > 1 ? 's' : '')
                : undefined;

            const twoByTwoStalls = sizeCounts['2m x 2m']
                ? `${sizeCounts['2m x 2m']} 2m x 2m Stall` + (sizeCounts['2m x 2m'] > 1 ? 's' : '')
                : undefined;

            const sizeCountsString = [threeByThreeStalls, twoByTwoStalls].filter(Boolean).join(' and ');

            const totalCapacity = (sizeCounts['2m x 2m'] || 0) * 2 + (sizeCounts['3m x 3m'] || 0) * 5;

            return { sizeCountsString, sizeCounts, totalCapacity, ...reservation };
        })
        .sort((a, b) => (new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1));

    const stallCountSummary = approvedReservationsWithSizeCounts.reduce(
        (acc, reservation) => {
            return {
                ...acc,
                threeByThreeStalls: acc.threeByThreeStalls + (reservation.sizeCounts['3m x 3m'] || 0),
                twoByTwoStalls: acc.twoByTwoStalls + (reservation.sizeCounts['2m x 2m'] || 0),
            };
        },
        { twoByTwoStalls: 0, threeByThreeStalls: 0 }
    );

    const stallCountSummaryString = Object.entries(stallCountSummary)
        .filter(([, count]) => count > 0)
        .map(
            ([size, count]) =>
                `${count} ${size.replace('threeByThreeStalls', '3m x 3m').replace('twoByTwoStalls', '2m x 2m')} Stall${
                    count > 1 ? 's' : ''
                }`
        )
        .join(' and ');

    // const {
    //     data: stallData,
    //     isLoading,
    //     refetch,
    // } = useQuery<CompanyStall>({
    //     queryKey: [QueryKeys.COMPANY_PROFILE],
    //     queryFn: () => profileApi.getCompanyProfile().then((res) => res.data),
    // });

    type DialogConfig = {
        [key: string]: {
            id: number;
            title: string | React.ReactNode;
            body: string | React.ReactNode;
            buttons: { label: string; onClick: () => void; colorScheme?: string }[];
        };
    };

    const dialogs: DialogConfig = {
        noActiveReservation: {
            id: 1,
            title: 'Something went wrong',
            body: 'No active reservation found! Please refresh the page',
            buttons: [{ label: 'OK', onClick: () => window.location.reload() }],
        },
        confirmReservation: {
            id: 1,
            title: 'Confirm Reservation',
            body: (
                <span>
                    Are you sure you want to confirm this stall selection? <br />
                    <small>
                        The stalls you have selected will be set as <strong>Booked</strong>
                    </small>
                </span>
            ),
            buttons: [
                { label: 'Yes', onClick: () => onUpdateReservation(ReservationStatus.Waiting), colorScheme: 'brand' },
                { label: 'No', onClick: () => console.log('Not confirmed'), colorScheme: 'dark' },
            ],
        },
        updateReservation: {
            id: 1,
            title: 'Update Reservation',
            body: (
                <span>
                    Are you sure you want to update this stall selection? <br />
                    <small>
                        The stalls you have selected will be set as <strong>Available</strong>
                    </small>
                </span>
            ),
            buttons: [
                { label: 'Yes', onClick: () => onUpdateReservation(ReservationStatus.Cancelled), colorScheme: 'brand' },
                { label: 'No', onClick: () => console.log('Not confirmed'), colorScheme: 'dark' },
            ],
        },
    };

    // =================================================================================================================

    const fetchStallData = useCallback(async () => {
        try {
            setStallDataLoading(true);
            const res = await stallApi.getStalls();
            const fetchedStalls = res.data;

            // Update the status of stalls in selectedStalls
            if (fetchedStalls.length > 0) {
                setSelectedStalls((prevSelectedStalls) =>
                    prevSelectedStalls.map((selectedStall) => {
                        const updatedStall = fetchedStalls?.find(
                            (stall: any) => stall.stallID === selectedStall.stallID
                        );
                        return updatedStall
                            ? {
                                  ...selectedStall,
                                  status: updatedStall.status,
                              }
                            : selectedStall;
                    })
                );

                setStallData(fetchedStalls);
            } else {
                setStallData([]);
            }
        } catch (e) {
            console.error('Failed to fetch stall data:', e);
            toast({ title: 'Failed to fetch stall data, please refresh', status: 'error' });
        } finally {
            setStallDataLoading(false);
        }
    }, [toast]);

    const fetchReservationData = useCallback(async () => {
        try {
            setLoading(true);
            setReservationDataLoading(true);

            if (props.userType !== 'company' && companyId === '') {
                return;
            }

            // Fetch reservations based on user type
            let reservationsResponse;
            if (props.userType === 'company') {
                reservationsResponse = await reservationApi.getReservations();
            } else if (props.userType === 'admin') {
                reservationsResponse = await reservationApi.getCompanyReservationByCompanyIdAdmin(companyId);
            }

            if (!reservationsResponse) throw Error();

            const reservations = await reservationsResponse.data;
            setReservations(reservations);

            // Determine guidelines visibility and current stage
            const hasReservations = reservations?.length > 0;
            if (!hasReservations) {
                setShowGuideLines(true);
                setCurrentStage(null);
                return;
            } else {
                setShowGuideLines(false);
            }

            // Set the last waiting or pending reservation as active
            const lastReservation = reservations[reservations.length - 1];

            if (
                lastReservation?.status === ReservationStatus.Waiting ||
                lastReservation?.status === ReservationStatus.Pending
            ) {
                setCurrentStage(lastReservation.status);
                if (lastReservation.stalls?.length > 0) {
                    setSelectedStalls(lastReservation.stalls);
                }
                setActiveReservation(lastReservation);
                setPaymentAmount(lastReservation.paymentAmount);
                setProofUrl(lastReservation.paymentProof);
            }

            if (lastReservation?.stalls.length === 0) {
                setCurrentStage(null);
            }

            // Separate reservations by status
            const filterByStatus = (status: string) =>
                reservations.filter((reservation: ReservationModel) => reservation.status === status);

            const approvedReservations = filterByStatus(ReservationStatus.Approved);
            if (approvedReservations.length > 0) {
                setApprovedReservations(approvedReservations);
                return;
            }

            // Handle waiting or pending reservations
            const handleReservationDetails = (reservation: ReservationModel) => {
                // if (selectedStalls.length === 0) {
                //     setSelectedStalls(reservation.stalls);
                // }
                setPaymentAmount(reservation?.paymentAmount ?? 0);
                setReservationId(reservation?._id ?? '');
                setReservationStatus(reservation?.status);
            };

            const waitingReservations = filterByStatus(ReservationStatus.Waiting);
            if (waitingReservations.length > 0) {
                handleReservationDetails(waitingReservations[waitingReservations.length - 1]);
                return;
            }

            const pendingReservations = filterByStatus(ReservationStatus.Pending);
            if (pendingReservations.length > 0) {
                const lastPending = pendingReservations[pendingReservations.length - 1];
                handleReservationDetails(lastPending);
                setProofUrl(lastPending?.paymentProof ?? '');
            }
        } catch (error) {
            toast({ title: 'Failed to fetch reservation data, please refresh', status: 'error' });
        } finally {
            setReservationDataLoading(false);
            setLoading(false);
        }
    }, [companyId, props.userType, toast]);

    const onPaymentProofSubmit = async (data: any) => {
        setLoading(true);

        if (activeReservation?._id == null) {
            toast({ title: 'No active reservation found, please refresh', status: 'error' });
            return;
        }
        const request: SubmitPaymentProofRequest = {
            updatedAt: new Date(),
            reservationId: activeReservation._id,
            proofUrl: '',
            ...(data.remarks ? { remarks: data.remarks } : {}),
        };
        try {
            if (acceptedFiles.length > 0) {
                let payload = new FormData();
                payload.append('file', acceptedFiles[0]);
                const fileUploadRes = await fileApi.uploadFile(payload);
                if (!fileUploadRes.data.url) throw Error('Error uploading file');
                request.proofUrl = fileUploadRes.data.url;
            } else {
                setLoading(false);
                toast({
                    title: 'Upload Payment Receipt',
                    description: 'Please upload the receipt as proof of payment',
                    status: 'error',
                    variant: 'solid',
                    duration: 6000,
                    isClosable: true,
                });
                return;
            }
            reservationApi
                .submitProofofPayment(request)
                .then((res) => {
                    setLoading(false);
                    if (res.data.stalls) {
                        let tmpResStalls = '';
                        for (let resStall of res.data.stalls) tmpResStalls += ` ${resStall},`;
                        toast({
                            title: `Could not finalize your reservation, stalls ${tmpResStalls} have been booked by another company`,
                            status: 'error',
                        });
                    } else {
                        if (props.userType === 'company') {
                            toast({
                                title: 'Payment receipt upload successful, please wait for approval',
                                status: 'success',
                            });
                            // setProps((p) => ({ ...p }));
                        } else if (props.userType === 'admin') {
                            history.push('/admin/stalls');
                            toast({ title: 'Stall reservation successful', status: 'success' });
                        }
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                    if (err.response && err.response.status === 500) {
                        toast({ title: err.response.data.message, status: 'error' });
                    } else
                        toast({
                            title: 'Something went wrong! Please refresh the page or try again later',
                            status: 'error',
                        });
                })
                .finally(() => {
                    fetchStallData();
                    fetchReservationData();
                });
        } catch (error) {
            console.error(error);
            setLoading(false);
            toast({
                title: 'File Upload failed',
                description: 'Plese check the network connection!',
                status: 'error',
                variant: 'solid',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const onUpdateReservation = async (status: ReservationStatus) => {
        setConfirmLoading(true);
        const payment = selectedStalls.reduce(
            (sum: any, stall: any) => sum + (stall.price || 0),
            activeReservation?.sponsorship?.price || 0
        );
        setPaymentAmount(payment);

        if (
            status !== ReservationStatus.Cancelled &&
            selectedStalls.filter((stall) => stall.status !== 'available').length > 0
        ) {
            setConfirmLoading(false);
            toast({ title: 'Selection contains stalls that are unavailable', status: 'warning' });
            return;
        }

        let reservationData: ReservationCreateOrUpdateRequest = {
            company: props.userType === 'company' ? company.id : companyId,
            status: status,
            paymentAmount: payment,
            stalls: selectedStalls,
        };

        try {
            setReservationDataLoading(true);

            if (status === ReservationStatus.Waiting) {
                let response = null;
                if (activeReservation && activeReservation.status === ReservationStatus.Waiting) {
                    response = await reservationApi.updateReservation(reservationData, activeReservation._id);
                    if (response?.data) {
                        toast({
                            title: 'Stall reservation submitted, please upload payment receipt to finalize',
                            status: 'success',
                        });
                        setCurrentStage(ReservationStatus.Waiting);
                        console.log('Reservation updated:', response?.data);
                        fetchReservationData();
                        fetchStallData();
                    }
                } else {
                    response = await reservationApi.createReservation(reservationData);
                    if (response?.data) {
                        console.log('New reservation confirmed:', response?.data);
                        setCurrentStage(ReservationStatus.Waiting);
                    }
                    fetchReservationData();
                    fetchStallData();
                }
            }

            if (status === ReservationStatus.Cancelled) {
                // If active reservation has a sponsorship, Do NOT cancel it - will be handled by the backend
                // if (activeReservation?.sponsorship) {
                //     reservationData.status = ReservationStatus.Waiting;
                // }
                reservationData.paymentAmount = activeReservation?.sponsorship?.price || 0;
                reservationData.stalls = [];

                const response = await reservationApi.updateReservation(
                    reservationData,
                    reservations[reservations.length - 1]._id
                );
                if (response?.data) {
                    setCurrentStage(ReservationStatus.Cancelled);
                    fetchReservationData();
                    fetchStallData();
                }
            }
        } catch (error: any) {
            if (status === ReservationStatus.Cancelled) {
                toast({ title: 'Failed to cancel previous reservation', status: 'error' });
            } else {
                toast({ title: 'Failed to confirm reservation, please refresh!', status: 'error' });
            }

            if (error.response && error.response.data && error.response.data.stallStatus) {
                const stallStatus = error.response.data.stallStatus;

                setSelectedStalls((prevStalls) =>
                    prevStalls.map((stall) => {
                        const updatedStatus = stallStatus.find((s: any) => s.stallID === stall.stallID);
                        return updatedStatus ? { ...stall, status: updatedStatus.status } : stall;
                    })
                );
            } else {
                console.error('Failed to confirm reservation:', error);
            }
        } finally {
            setConfirmLoading(false);
            setReservationDataLoading(false);
        }
    };

    // =================================================================================================================

    const getStallTypeBySize = (size: StallSize): keyof typeof STALL_INFO | undefined => {
        return Object.keys(STALL_INFO).find((key) => STALL_INFO[key as keyof typeof STALL_INFO].size === size) as
            | keyof typeof STALL_INFO
            | undefined;
    };

    const calculateStallPrice = (
        size: StallSize,
        stalls: ReservationStall[],
        sponsorshipData: ReservationSponsorship
    ) => {
        const stallType = getStallTypeBySize(size);
        if (!stallType) {
            console.error(`Invalid stall size: ${size}`);
            return 0;
        }

        const { price: originalPrice, subsequentPrice } = STALL_INFO[stallType];

        const sameSizeStalls = stalls.filter((stall) => stall.size === size).length;

        const freeStallsCount = sponsorshipData?.freeStallsCount || 0;

        // Apply free stall logic only for 3m x 3m stalls
        if (size === '3m x 3m' && sameSizeStalls < freeStallsCount) {
            return 0;
        }

        // First stall gets the original price, subsequent ones get reduced price
        // And if already made reservations before get reduced price
        return approvedReservations.length > 0
            ? subsequentPrice
            : stalls.length === 0
              ? originalPrice
              : subsequentPrice;
    };

    const addStall = (
        stalls: ReservationStall[],
        currentStallData: StallModel,
        sponsorshipData: ReservationSponsorship
    ) => {
        const calculatedPrice = calculateStallPrice(currentStallData.size, stalls, sponsorshipData);
        return [
            ...stalls,
            {
                documentID: currentStallData._id,
                stallID: currentStallData.stallID,
                status: currentStallData.status,
                price: calculatedPrice,
                size: currentStallData.size,
                capacity: currentStallData.capacity,
            } as ReservationStall,
        ];
    };

    const removeStall = (
        stalls: ReservationStall[],
        currentStallData: StallModel,
        sponsorshipData: ReservationSponsorship
    ) => {
        const updatedStalls = stalls.filter((stall) => stall.stallID !== currentStallData.stallID);

        const recalculatedStalls = updatedStalls.map((stall, index) => {
            const recalculatedPrice = calculateStallPrice(stall.size, updatedStalls.slice(0, index), sponsorshipData);
            return { ...stall, price: recalculatedPrice };
        });

        return recalculatedStalls;
    };

    const handleStallClick = (currentStallData: StallModel) => {
        if (showGuideLines) {
            setShowGuideLines(false);
        }
        let res: ReservationStall[] = [];
        const isSelected = selectedStalls.some((stall) => stall.stallID === currentStallData.stallID);

        if (isSelected) {
            // Remove stall
            res = removeStall(selectedStalls, currentStallData, activeReservation?.sponsorship);
        } else {
            // Add stall
            res = addStall(selectedStalls, currentStallData, activeReservation?.sponsorship);
        }

        setSelectedStalls(res);
        setPaymentAmount(
            res.reduce((sum: any, stall: any) => sum + (stall.price || 0), activeReservation?.sponsorship?.price || 0)
        );
    };

    const groupBySize = (stalls: any[]) => {
        return stalls.reduce((acc: any, stall: any) => {
            const { size, price, stallID } = stall;
            if (!acc[size]) {
                acc[size] = [];
            }
            acc[size].push({ price, stallID });
            return acc;
        }, {});
    };

    const changeCompany = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCompanyId(event.target.value as string);
    };

    const StallTable = ({
        selectedStalls,
        sponsorshipData,
    }: {
        selectedStalls: ReservationStall[];
        sponsorshipData: ReservationSponsorship;
    }) => {
        const groupedStalls = groupBySize(selectedStalls.filter((stall: any) => stall.price !== 0));
        const freeStalls = selectedStalls.filter((stall: any) => stall.price === 0);

        const stallRows = Object.keys(groupedStalls).map((size) => {
            const stalls = groupedStalls[size];
            const stallTags = stalls
                ?.filter((stall: any) => stall.price !== 0)
                .map((stall: any) => (
                    <Tag key={stall.stallID} size={'xs'} px={1} style={{ margin: 0 }} colorScheme={'blackAlpha'}>
                        {stall.stallID}
                    </Tag>
                ));

            return (
                <Tr key={size}>
                    <Td textAlign={'left'}>
                        <VStack gap={0} alignItems={'start'}>
                            <Heading size={'xs'}>{size} Stalls</Heading>
                            <HStack gap={1.5} width={'100%'} style={{ maxWidth: 200 }} wrap={'wrap'}>
                                {stallTags}
                            </HStack>
                        </VStack>
                    </Td>
                    <Td textAlign={'center'}>{stalls.length}</Td>
                    <Td textAlign={'right'}>
                        {formatCurrency(stalls.reduce((sum: any, stall: any) => sum + stall.price, 0))}
                    </Td>
                </Tr>
            );
        });

        return (
            <>
                <TableContainer mb={2}>
                    <Table variant="simple" size={'sm'}>
                        <Thead>
                            <Tr>
                                <Th textAlign={'left'}>Item</Th>
                                <Th textAlign={'center'}>Qty</Th>
                                <Th textAlign={'right'}>Price</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {freeStalls.length > 0 && (
                                <Tr>
                                    <Td textAlign={'left'}>
                                        <VStack gap={0} alignItems={'stretch'}>
                                            <Heading size={'xs'} color={'green'}>
                                                Free Stalls (3m x 3m)
                                            </Heading>
                                            <HStack gap={1} wrap={'wrap'}>
                                                {freeStalls.map((stall: any) => (
                                                    <Tag key={stall.stallID} size={'xs'} px={1} colorScheme={'green'}>
                                                        {stall.stallID}
                                                    </Tag>
                                                ))}
                                            </HStack>
                                        </VStack>
                                    </Td>
                                    <Td color={'green'} textAlign={'center'}>
                                        {freeStalls.length}
                                    </Td>
                                    <Td color={'green'} textAlign={'right'}>
                                        {formatCurrency(0)}
                                    </Td>
                                </Tr>
                            )}

                            {stallRows}

                            {activeReservation?.sponsorship && (
                                <Tr>
                                    <Td textAlign={'left'}>
                                        <VStack gap={0} alignItems={'stretch'}>
                                            <Heading size={'xs'}>{sponsorshipData?.package} Sponsorship</Heading>
                                        </VStack>
                                    </Td>
                                    <Td textAlign={'center'}>1</Td>
                                    <Td textAlign={'right'}>{formatCurrency(sponsorshipData?.price)}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>

                <HSeparator />

                <TableContainer mb={1} mt={2}>
                    <Table variant="simple" size={'sm'}>
                        <Tbody>
                            <Tr>
                                <Td textAlign={'left'}>
                                    <Heading size={'md'}>Total Payment</Heading>
                                </Td>
                                <Td textAlign={'right'}>
                                    <Heading size={'md'}>{formatCurrency(paymentAmount)}</Heading>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </>
        );
    };

    useEffect(() => {
        console.log('Fetching stalls and reservations...');
        fetchStallData();
        fetchReservationData();
    }, [companyId]);

    useEffect(() => {
        if (props.userType === 'admin') {
            companyApi.getCompanyList().then((res) => {
                if (res.data.length > 0) {
                    setCompanyId(res.data[0]._id);
                }
                setCompanies(res.data);
            });
        }
    }, [props]);

    useEffect(() => {
        if (props.userType === 'company') {
            profileApi.getCompanyProfile().then((res) => {
                setCompany(res.data);
                setCompanyId(res.data._id);
            });
        }
    }, [props]);

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            if (activeReservation == null && selectedStalls.length > 0) {
                event.preventDefault();
                event.returnValue = ''; // Some browsers require this for the dialog to appear
                return ''; // For older browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [activeReservation, selectedStalls]);

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            {activeDialog && (
                <CustomAlertDialog
                    isOpen={true}
                    onClose={closeDialog}
                    title={dialogs[activeDialog].title}
                    body={dialogs[activeDialog].body}
                    buttons={dialogs[activeDialog].buttons.map((btn) => ({
                        ...btn,
                        onClick: () => {
                            btn.onClick();
                            closeDialog(); // Close dialog after button action
                        },
                    }))}
                />
            )}
            {company?.sponsorship != null && (
                <Alert variant={'left-accent'} status="success" mb={4}>
                    <AlertIcon />
                    <AlertDescription>
                        <Heading size={'sm'} fontWeight={'normal'}>
                            You have successfully reserved a <strong>{company.sponsorship?.package} Sponsorship</strong>{' '}
                            worth {formatCurrency(company.sponsorship?.price)}
                        </Heading>

                        <small>
                            You get <strong>{company.sponsorship?.freeStallsCount} stalls (3m x 3m) </strong> for free.
                            Please contact{' '}
                            <a href={'mailto:careerfair@ucsc.cmb.ac.lk'} style={{ fontStyle: 'italic' }}>
                                careerfair@ucsc.cmb.ac.lk
                            </a>{' '}
                            for inquiries
                        </small>
                    </AlertDescription>
                </Alert>
            )}

            <Grid templateColumns={{ lg: '3fr 2fr' }} gap={6}>
                <Card mb={{ base: '0px', lg: '20px' }} alignItems="center" style={{ height: 'fit-content' }}>
                    <Box w={'full'}>
                        {props.userType === 'admin' ? (
                            <Heading size={'md'}>
                                {companyId === ''
                                    ? 'Reserve Stalls for a Company'
                                    : 'Reserve Stalls for ' + companies.find((x) => x._id === companyId)?.name}
                            </Heading>
                        ) : (
                            <VStack gap={0.5} alignItems="start">
                                <Heading size={'md'}>Reserve Your Stall</Heading>
                                <Heading size={'sm'} color={'#9E9E9E'}>
                                    Hotel Marino Beach - Banquet Hall
                                </Heading>
                            </VStack>
                        )}
                    </Box>
                    <div style={{ width: '100%' }}>
                        <div>
                            <HStack mt={5} justifyContent="space-between">
                                {stallData && (
                                    <Text>
                                        <strong>
                                            {stallData?.filter((stall: any) => stall.status === 'available').length}
                                        </strong>{' '}
                                        Stall(s) Available
                                    </Text>
                                )}
                                <Checkbox
                                    mt={2}
                                    isChecked={showMarkings}
                                    onChange={(e) => setShowMarkings(e.target.checked)}
                                >
                                    Show Markings
                                </Checkbox>
                            </HStack>
                            <Heading size={'md'} textAlign="center" mt={4} mb={4}>
                                Floor Plan
                            </Heading>
                            <StallMapNew
                                readOnly={
                                    stallDataLoading ||
                                    currentStage === ReservationStatus.Waiting ||
                                    currentStage === ReservationStatus.Pending ||
                                    currentStage === ReservationStatus.Approved
                                }
                                showMarkings={showMarkings}
                                selectedStalls={selectedStalls}
                                handleStallClick={handleStallClick}
                                stallDataLoading={stallDataLoading}
                                stallData={stallData}
                                approvedReservations={approvedReservations}
                            />
                        </div>
                    </div>
                </Card>

                <Box>
                    {/*Company Selection for Admin*/}
                    {props.userType === 'admin' && (
                        <>
                            <Grid>
                                <Card>
                                    <Box mb={4}>
                                        <h3 style={{ marginBottom: '20px' }}>Select the company You want to manage</h3>
                                        <FormControl>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <FormLabel id="company-label">Company</FormLabel>
                                                <select value={companyId} onChange={changeCompany}>
                                                    {companies?.map((comp) => (
                                                        <option key={comp._id} value={comp._id}>
                                                            {' '}
                                                            {comp?.name}{' '}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </FormControl>

                                        <Box>
                                            <h3>
                                                {companies.find((x) => x._id === companyId)?.name + ' '} has Already
                                                Reserved Stalls
                                            </h3>
                                            <h4>
                                                Reserved Stalls are :{' '}
                                                <strong>
                                                    {activeReservation?.stalls.map((stall) => `${stall.stallID} `)}
                                                </strong>
                                            </h4>

                                            <p style={{ marginBottom: '20px' }}>
                                                Reservation Status :{' '}
                                                {activeReservation?.status === ReservationStatus.Pending ? (
                                                    <strong>Pending Approval</strong>
                                                ) : activeReservation?.status === ReservationStatus.Approved ? (
                                                    <strong>Reservation Confirmed</strong>
                                                ) : (
                                                    <strong>{activeReservation?.status}</strong>
                                                )}
                                            </p>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        </>
                    )}

                    {/*Instructions*/}
                    {props.userType === 'company' && (
                        <Button
                            size="sm"
                            leftIcon={<MdInfoOutline />}
                            onClick={() => {
                                setShowGuideLines(!showGuideLines);
                            }}
                            mb={4}
                            colorScheme={'cyan'}
                            variant={'outline'}
                        >
                            {showGuideLines ? 'Hide' : 'Show'} Stall Reservation Guidelines
                        </Button>
                    )}
                    {props.userType === 'company' && (
                        <Collapse in={showGuideLines}>
                            <Skeleton isLoaded={!loading}>
                                <Card mb={{ base: '0px', lg: '20px' }} style={{ height: 'fit-content' }}>
                                    <Box>
                                        <Heading size={'md'}>Welcome To The Stall Reservation Process</Heading>
                                        <Alert
                                            my={4}
                                            flexDirection="column"
                                            alignItems="start"
                                            justifyContent="center"
                                            status="info"
                                            borderRadius={8}
                                        >
                                            <AlertTitle> Note:</AlertTitle>
                                            <AlertDescription>
                                                You can reserve more stalls as you wish and make payments, but you
                                                cannot remove stalls that you have already reserved and made payment
                                                for. You can contact the organizing committee if you need to make any
                                                changes to your reservation.
                                            </AlertDescription>
                                        </Alert>
                                        <VStack mt={4} mb={2}>
                                            <Heading size={'sm'} width={'100%'}>
                                                Step 1: Click to select stalls from the floor plan
                                            </Heading>
                                            <Box p={1} px={4}>
                                                <ul>
                                                    <li>
                                                        Please select any number of stalls, from the adjacent floor
                                                        plan, your selection will be displayed with details about each
                                                        stall.
                                                    </li>
                                                    <li>
                                                        The pricing structure is as follows:
                                                        <br />
                                                        <strong>For {STALL_INFO.large.size} stalls: </strong>The first
                                                        stall will cost {formatCurrency(STALL_INFO.large.price)} and
                                                        subsequent stalls will cost{' '}
                                                        {formatCurrency(STALL_INFO.large.subsequentPrice)} each.
                                                        <br />
                                                        <strong>For {STALL_INFO.small.size} stalls: </strong>The first
                                                        stall will cost {formatCurrency(STALL_INFO.small.price)} and
                                                        subsequent stalls will cost{' '}
                                                        {formatCurrency(STALL_INFO.small.subsequentPrice)} each.
                                                    </li>
                                                </ul>
                                            </Box>
                                        </VStack>
                                        <VStack mb={2}>
                                            <Heading size={'sm'} my={2} width={'100%'}>
                                                Step 2: Confirm your selection to reserve the stalls under your company
                                            </Heading>
                                            <Box p={1} px={4}>
                                                <ul>
                                                    <li>
                                                        A read-only list with details about your selected stalls and
                                                        also about sponsorships (if any) will be displayed.
                                                    </li>
                                                    <li>
                                                        In case you need to do any changes, you can edit your selection
                                                        of stalls. But the ability to save the reservation for the
                                                        stalls will depend on the stall availability at the time.
                                                    </li>
                                                </ul>
                                            </Box>
                                        </VStack>
                                        <VStack mb={2}>
                                            <Heading size={'sm'} my={2} width={'100%'}>
                                                Step 3: Make payment and submit payment proof to confirm your
                                                reservation
                                            </Heading>
                                            <Box p={1} px={4}>
                                                <ul>
                                                    <li>
                                                        You can make the payment within 7 days to finalize your stall
                                                        reservation.
                                                    </li>
                                                    <li>
                                                        If you need to purchase more stalls, you can do so based on the
                                                        availability at the time, by following the same process.
                                                    </li>
                                                </ul>
                                            </Box>
                                        </VStack>
                                    </Box>
                                </Card>
                            </Skeleton>
                        </Collapse>
                    )}

                    {/* if there are any paid reservations */}
                    <Collapse
                        in={
                            (props.userType === 'company' || props.userType === 'admin') &&
                            approvedReservations.length > 0
                        }
                        unmountOnExit
                    >
                        <Card mb={{ base: '0px', lg: '20px' }} style={{ height: 'fit-content' }}>
                            <Skeleton isLoaded={true}>
                                <Grid>
                                    <Box>
                                        <>
                                            <Heading size={'sm'} mb={5} textAlign={'center'}>
                                                {props.userType === 'company'
                                                    ? 'Approved Stall Reservations'
                                                    : `Approved Stall Reservations for ${selectedCompany?.name}`}
                                            </Heading>

                                            {approvedReservationsWithSizeCounts.length > 1 && (
                                                <div style={{ marginBottom: '20px' }}>
                                                    {props.userType === 'company'
                                                        ? 'You have'
                                                        : `${selectedCompany?.name} has`}{' '}
                                                    reserved {stallCountSummaryString}
                                                </div>
                                            )}

                                            <Accordion allowMultiple allowToggle mt={2} mb={1}>
                                                {approvedReservationsWithSizeCounts.map((reservationData, index) => (
                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton>
                                                                <Box flex="1" textAlign="left">
                                                                    <strong>Reservation {index + 1}</strong>
                                                                    &ensp;
                                                                    <Tag colorScheme={'green'}>
                                                                        {new Date(
                                                                            reservationData.updatedAt
                                                                        ).toLocaleDateString()}
                                                                    </Tag>
                                                                    &ensp; ({reservationData.sizeCountsString})
                                                                </Box>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={2}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <Thead>
                                                                        <Tr>
                                                                            <Th>Allocated Stalls</Th>
                                                                            <Th textAlign={'center'}>Total Capacity</Th>
                                                                            <Th textAlign={'center'}>Price</Th>
                                                                            <Th>Payment Receipt</Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                        <Tr>
                                                                            <Td>
                                                                                <HStack
                                                                                    spacing={0}
                                                                                    align={'center'}
                                                                                    wrap={'wrap'}
                                                                                    style={{ maxWidth: 240 }}
                                                                                    gap={2}
                                                                                >
                                                                                    {reservationData.stalls.map(
                                                                                        (stall, index) => (
                                                                                            <Tag
                                                                                                size={'md'}
                                                                                                key={index}
                                                                                                variant="solid"
                                                                                                colorScheme="teal"
                                                                                            >
                                                                                                {stall.stallID}
                                                                                            </Tag>
                                                                                        )
                                                                                    )}
                                                                                </HStack>
                                                                            </Td>

                                                                            <Td textAlign={'center'}>
                                                                                {reservationData.totalCapacity}
                                                                            </Td>

                                                                            <Td textAlign={'center'}>
                                                                                <HStack
                                                                                    direction="row"
                                                                                    spacing={1}
                                                                                    alignItems="left"
                                                                                >
                                                                                    {
                                                                                        <Tag
                                                                                            size="md"
                                                                                            variant="subtle"
                                                                                            colorScheme="error"
                                                                                        >
                                                                                            {formatCurrency(
                                                                                                reservationData.paymentAmount
                                                                                            )}
                                                                                        </Tag>
                                                                                    }
                                                                                </HStack>
                                                                            </Td>
                                                                            <Td textAlign={'center'}>
                                                                                {reservationData.paymentProof ==
                                                                                    null && (
                                                                                    <Button size="sm" variant="subtle">
                                                                                        N/A
                                                                                    </Button>
                                                                                )}
                                                                                {reservationData.paymentProof && (
                                                                                    <Button size="sm" variant="outline">
                                                                                        <a
                                                                                            href={
                                                                                                reservationData.paymentProof
                                                                                            }
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            View
                                                                                        </a>
                                                                                    </Button>
                                                                                )}
                                                                            </Td>
                                                                        </Tr>
                                                                    </Tbody>
                                                                </Table>
                                                            </TableContainer>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                            {/* </VStack> */}
                                        </>
                                    </Box>
                                </Grid>
                            </Skeleton>
                        </Card>
                    </Collapse>

                    {(props.userType === 'company' || props.userType === 'admin') && (
                        <Collapse
                            in={currentStage === null || currentStage === ReservationStatus.Cancelled}
                            unmountOnExit
                        >
                            <Card mb={{ base: '0px', lg: '20px' }} style={{ height: 'fit-content' }}>
                                <Skeleton isLoaded={!reservationDataLoading}>
                                    <Grid>
                                        {
                                            <Box>
                                                <>
                                                    <Heading size={'sm'} mb={3} textAlign={'center'}>
                                                        Stall Selection
                                                    </Heading>
                                                    <VStack
                                                        gap={0}
                                                        alignItems={'stretch'}
                                                        style={{ maxHeight: 640, overflowY: 'auto' }}
                                                    >
                                                        {selectedStalls.length === 0 ? (
                                                            <Heading
                                                                size={'xs'}
                                                                my={2}
                                                                textAlign={'center'}
                                                                color={'gray'}
                                                            >
                                                                No stalls selected
                                                            </Heading>
                                                        ) : (
                                                            selectedStalls.sort().map((stallData: any) => (
                                                                <Stall
                                                                    key={stallData.stallID}
                                                                    id={stallData.stallID}
                                                                    title={'Stall ' + stallData.stallID}
                                                                    subTitle={`(${stallData.size})`}
                                                                    status={stallData.status}
                                                                    price={stallData.price}
                                                                    timeStamp={null}
                                                                    onDelete={() => {
                                                                        handleStallClick(stallData);
                                                                    }}
                                                                />
                                                            ))
                                                        )}
                                                    </VStack>
                                                </>
                                                <Box
                                                    mt={1}
                                                    mb={1}
                                                    display={'flex'}
                                                    justifyContent="center"
                                                    alignItems={'center'}
                                                    flexDirection={'column'}
                                                >
                                                    <VStack
                                                        mt={1}
                                                        mb={3}
                                                        gap={0}
                                                        width={'100%'}
                                                        justifyContent={'center'}
                                                    >
                                                        <>
                                                            {activeReservation?.sponsorship != null && (
                                                                <Tag colorScheme={'green'} fontWeight={'bold'} my={2}>
                                                                    {Math.max(
                                                                        activeReservation?.sponsorship.freeStallsCount -
                                                                            (selectedStalls.filter(
                                                                                (stall) => stall.price === 0
                                                                            ).length +
                                                                                reservations
                                                                                    ?.filter(
                                                                                        (reservation) =>
                                                                                            reservation.status ===
                                                                                            ReservationStatus.Approved
                                                                                    )
                                                                                    .reduce((count, reservation) => {
                                                                                        // Add the count of free stalls in each reservation
                                                                                        return (
                                                                                            count +
                                                                                            reservation.stalls.filter(
                                                                                                (stall: any) =>
                                                                                                    stall.price === 0
                                                                                            ).length
                                                                                        );
                                                                                    }, 0)),
                                                                        0
                                                                    )}
                                                                    &ensp;Free Stall(s) remaining
                                                                </Tag>
                                                            )}
                                                            <Heading size={'xs'} fontWeight={'normal'} mt={2}>
                                                                Total Quantity:{' '}
                                                                <span>{selectedStalls.length} Stalls</span>
                                                            </Heading>
                                                            <Heading size={'xs'} fontWeight={'normal'}>
                                                                Total Capacity:{' '}
                                                                <span>
                                                                    {selectedStalls.reduce(
                                                                        (total, stall) => total + stall.capacity,
                                                                        0
                                                                    )}
                                                                    {activeReservation?.sponsorship &&
                                                                        ` + ${activeReservation?.sponsorship.additionalRepCount}`}
                                                                    {' People'}
                                                                </span>
                                                            </Heading>
                                                        </>
                                                    </VStack>

                                                    <>
                                                        <Heading size={'sm'} mb={4}>
                                                            Payment for Stalls:{' '}
                                                            <span>
                                                                {formatCurrency(
                                                                    selectedStalls.reduce(
                                                                        (total, stall) => total + stall.price,
                                                                        0
                                                                    )
                                                                )}
                                                            </span>
                                                        </Heading>
                                                        <Button
                                                            disabled={selectedStalls.length === 0}
                                                            isLoading={confirmLoading}
                                                            mt={1}
                                                            variant={'brand'}
                                                            onClick={() => openDialog('confirmReservation')}
                                                        >
                                                            Confirm Reservation
                                                        </Button>
                                                    </>
                                                </Box>
                                            </Box>
                                        }
                                    </Grid>
                                </Skeleton>
                            </Card>
                        </Collapse>
                    )}

                    {/*Summary Table*/}
                    {/* To be displayed only if */}
                    {(props.userType === 'company' || props.userType === 'admin') && (
                        <Collapse
                            in={
                                currentStage === ReservationStatus.Waiting || currentStage === ReservationStatus.Pending
                            }
                            unmountOnExit
                        >
                            <Card mb={{ base: '0px', lg: '20px' }} style={{ height: 'fit-content' }}>
                                <Skeleton isLoaded={!reservationDataLoading}>
                                    <Grid>
                                        <Box
                                            mt={1}
                                            mb={1}
                                            display={'flex'}
                                            justifyContent="center"
                                            alignItems={'center'}
                                            flexDirection={'column'}
                                        >
                                            <VStack mt={1} mb={3} gap={0} width={'100%'} justifyContent={'center'}>
                                                <Box width={'100%'}>
                                                    <StallTable
                                                        selectedStalls={activeReservation?.stalls ?? []}
                                                        sponsorshipData={activeReservation?.sponsorship}
                                                    />
                                                </Box>
                                            </VStack>

                                            {activeReservation?.status !== ReservationStatus.Pending && (
                                                <Button
                                                    isLoading={confirmLoading}
                                                    variant={'outline'}
                                                    size={'sm'}
                                                    mb={3}
                                                    onClick={() => openDialog('updateReservation')}
                                                >
                                                    Update Selection
                                                </Button>
                                            )}
                                            {activeReservation && (
                                                <Text size={'sm'} mt={1}>
                                                    Reserved on:{' '}
                                                    <strong>
                                                        {new Date(activeReservation?.createdAt).toLocaleString(
                                                            'en-US',
                                                            {}
                                                        )}
                                                    </strong>
                                                </Text>
                                            )}
                                        </Box>
                                    </Grid>
                                </Skeleton>
                            </Card>
                        </Collapse>
                    )}

                    <Collapse
                        in={currentStage === ReservationStatus.Waiting || currentStage === ReservationStatus.Pending}
                        unmountOnExit
                    >
                        <Card mb={{ base: '0px', lg: '20px' }} style={{ height: 'fit-content' }}>
                            <Heading size={'sm'} mb={5} textAlign={'center'}>
                                {activeReservation?.status === ReservationStatus.Waiting
                                    ? 'Payment Details'
                                    : 'Payment Review Status'}
                            </Heading>
                            <Box textAlign={'center'} mt={0}>
                                {currentStage === ReservationStatus.Waiting ? (
                                    <>
                                        <VStack mb={8} gap={4} as="form" onSubmit={handleSubmit(onPaymentProofSubmit)}>
                                            <VStack width="100%" align="stretch">
                                                <FormLabel
                                                    display="flex"
                                                    ms="4px"
                                                    fontSize="sm"
                                                    fontWeight="500"
                                                    color={textColor}
                                                    mb="8px"
                                                >
                                                    Payment Receipt<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <VStack
                                                    width={'100%'}
                                                    direction="column"
                                                    align="stretch"
                                                    justify="center"
                                                    bg={bg}
                                                    border="1px dashed"
                                                    borderColor={borderColor}
                                                    borderRadius="16px"
                                                    w="100%"
                                                    h="max-content"
                                                    minH="100%"
                                                    cursor="pointer"
                                                    {...getRootProps({ className: 'dropzone' })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <Button variant="no-effects" height="100%" py="10px">
                                                        <Box>
                                                            <Icon
                                                                as={MdUpload}
                                                                w="80px"
                                                                h="80px"
                                                                color="secondaryGray.700"
                                                            />
                                                            <Flex justify="center" mx="auto" mb="12px">
                                                                <Text
                                                                    fontSize="lg"
                                                                    fontWeight="700"
                                                                    color="secondaryGray.700"
                                                                >
                                                                    Upload Here
                                                                </Text>
                                                            </Flex>
                                                        </Box>
                                                    </Button>
                                                    {files.length > 0 && (
                                                        <Box display="flex" px="5px" py="10px">
                                                            <Icon
                                                                as={MdFilePresent}
                                                                color="secondaryGray.700"
                                                                mr="5px"
                                                            />{' '}
                                                            {files}
                                                        </Box>
                                                    )}
                                                </VStack>
                                            </VStack>
                                            <FormControl id="remarks" isDisabled={loading}>
                                                <FormLabel
                                                    display="flex"
                                                    ms="4px"
                                                    fontSize="sm"
                                                    fontWeight="500"
                                                    color={textColor}
                                                    mb="8px"
                                                >
                                                    Remarks
                                                </FormLabel>
                                                <Input
                                                    type="text"
                                                    variant="auth"
                                                    fontSize="sm"
                                                    ms={{ base: '0px', md: '0px' }}
                                                    fontWeight="500"
                                                    size="lg"
                                                    {...register('remarks')}
                                                />
                                                <FormErrorMessage
                                                    color={brandStars}
                                                    fontSize="sm"
                                                    fontWeight="semibold"
                                                ></FormErrorMessage>
                                            </FormControl>
                                            <Button variant="brand" type="submit" size="md" isLoading={loading}>
                                                Submit
                                            </Button>
                                        </VStack>
                                        <Alert mt={4} status="info" borderRadius={8}>
                                            <AlertIcon />
                                            <AlertDescription textAlign="left">
                                                Please complete the current reservation before making an additional
                                                reservation.
                                            </AlertDescription>
                                        </Alert>
                                    </>
                                ) : (
                                    <>
                                        {/* notice */}
                                        <Alert status="success" borderRadius={8} mb={4}>
                                            <AlertIcon />
                                            <AlertDescription textAlign="left">
                                                Your payment is being reviewed, please await confirmation
                                            </AlertDescription>
                                        </Alert>

                                        {activeReservation && (
                                            <Text size={'sm'} mt={3} mb={2}>
                                                Submitted on:{' '}
                                                <strong>
                                                    {new Date(activeReservation?.updatedAt).toLocaleString('en-US', {})}
                                                </strong>
                                            </Text>
                                        )}

                                        {/* proof */}
                                        <Button size="sm" variant="outline" my={1}>
                                            <a href={proofUrl} target="_blank" rel="noopener noreferrer">
                                                View uploaded payment receipt
                                            </a>
                                        </Button>

                                        {/* notice */}
                                        <VStack mt={4} justifyContent="start">
                                            <Text style={{ textAlign: 'left' }}>
                                                <strong>Note:</strong> Please contact the organizing committee to speed
                                                up the review process if you want to make an additional reservation.
                                            </Text>
                                        </VStack>
                                    </>
                                )}
                            </Box>
                        </Card>
                    </Collapse>
                </Box>
            </Grid>
        </Box>
    );
}

export default StallBooking;
