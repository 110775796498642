import { Alert, AlertIcon, AlertTitle, Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

export default function Sponsorships() {
    return (
        <Box p={4} mt={10}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}></Text>
            <Table variant="striped" colorScheme="red" size="md" style={{ background: 'white' }}>
                <Thead>
                    <Tr bg="gray.200" py={4}>
                        <Th fontSize="xl" fontWeight="bold" textAlign="left">
                            Benefits
                        </Th>
                        <Th fontSize="xl" fontWeight="bold" color="gray.500" textAlign="center">
                            Silver
                        </Th>
                        <Th fontSize="xl" fontWeight="bold" color="yellow.500" textAlign="center">
                            Gold
                        </Th>
                        <Th fontSize="xl" fontWeight="bold" color="orange.500" textAlign="center">
                            Platinum
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td textAlign="left">Free stalls (3m x 3m)</Td>
                        <Td textAlign="center">1</Td>
                        <Td textAlign="center">2</Td>
                        <Td textAlign="center">3</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Extra company representatives</Td>
                        <Td textAlign="center">1</Td>
                        <Td textAlign="center">2</Td>
                        <Td textAlign="center">3</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Sponsorship introductory post</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Video advertisements in LED Display throughout the event day</Td>
                        <Td textAlign="center">One video (2 mins)</Td>
                        <Td textAlign="center">Two videos (3 mins each)</Td>
                        <Td textAlign="center">Two videos (4 mins each)</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Option to distribute marketing material</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Company logo in organizing committee tags</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Company logo featured in the backdrop</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Company logo featured in file covers</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Company logo featured in banners (inside and outside)</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Company logo featured in footers of photographs taken</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="left">Pre-event workshop</Td>
                        <Td textAlign="center">✘</Td>
                        <Td textAlign="center">✔</Td>
                        <Td textAlign="center">✔</Td>
                    </Tr>
                    <Tr fontWeight={'bold'}>
                        <Td textAlign="left">Price (LKR)</Td>
                        <Td textAlign="center">150,000.00</Td>
                        <Td textAlign="center">300,000.00</Td>
                        <Td textAlign="center">400,000.00</Td>
                    </Tr>
                </Tbody>
            </Table>
            <Text fontSize="sm" my={6}>
                * All sponsors can purchase additional stalls if required. <br />* Sponsorship details and selected
                packages must be confirmed via email, and there will be an option to select the package during the
                registration process.
            </Text>
            <Alert status="info" size="xs" style={{ marginTop: '10px' }}>
                <AlertIcon />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AlertTitle style={{ marginBottom: '4px' }}>
                        Please reach out to us at{' '}
                        <a href="mailto:careerfair@ucsc.cmb.ac.lk">
                            <u>careerfair@ucsc.cmb.ac.lk</u>
                        </a>{' '}
                        for sponsorships
                    </AlertTitle>
                </div>
            </Alert>
        </Box>
    );
}
