import { ChakraProvider } from '@chakra-ui/react';
import axios from 'axios';
import { baseUrl } from 'constant/baseUrl';
import { AuthContextProvider } from 'contexts/AuthContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import './assets/css/App.css';
import { logout } from './firebase';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import CompanyLayout from './layouts/company';
import StudentLayout from './layouts/student';
import theme from './theme/theme';

// Axios Config
axios.defaults.baseURL = baseUrl;
axios.interceptors.request.use((request) => {
    const token = localStorage.getItem('API-AUTH-TOKEN');
    const isLoggedIn = token == null ? false : true;

    if (isLoggedIn) {
        request.headers.Authorization = `Bearer ${token}`;
    } else {
        // Navigate to Login
    }
    return request;
});

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    async function (error) {
        if (error.response?.status === 401) {
            await logout().then((e) => {
                window.location.href = '/';
            });
        }

        return Promise.reject(error);
    }
);

// End Axios Config

// configure react query client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            retry: true,
            // TODO: Enable only after verifying proper invalidation is done on the queries
            // cacheTime: 1000 * 60 * 60 * 1, // 1 hour
        },
    },
});

console.log('v2.0.9');

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
            <AuthContextProvider>
                <React.StrictMode>
                    <HashRouter>
                        <Switch>
                            <Route path={`/auth`} component={AuthLayout} />
                            <Route path={`/company`} component={CompanyLayout} />
                            <Route path={`/admin`} component={AdminLayout} />
                            <Route path={`/student`} component={StudentLayout} />
                            <Redirect from="/" to="/auth" />
                        </Switch>
                    </HashRouter>
                </React.StrictMode>
            </AuthContextProvider>
        </ChakraProvider>
    </QueryClientProvider>,
    document.getElementById('root')
);
