// Chakra imports
import { Box, Portal, useDisclosure } from '@chakra-ui/react';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { useAuth } from 'contexts/AuthContext';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import all_routes from 'routes';

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
    const { ...rest } = props;
    const auth = useAuth();
    const { onOpen } = useDisclosure();
    const routes = all_routes.filter((route) => route.layout === '/admin');
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);

    if (!auth.isLoggedIn || auth.user.role !== 'admin') {
        return <Redirect to="/auth/sign-in" />;
    }

    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };

    const getActiveRoute = (routes: RoutesType[]): string => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].secondary;
            }
        }
        return activeNavbar;
    };

    const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route: RoutesType, key: any) => {
            if (route.layout === '/admin') {
                return <Route path={route.layout + route.path} component={route.component} key={key} />;
            } else {
                return null;
            }
        });
    };

    document.documentElement.dir = 'ltr';

    return (
        <Box>
            <SidebarContext.Provider
                value={{
                    toggleSidebar,
                    setToggleSidebar,
                }}
            >
                <Sidebar routes={routes} display="none" {...rest} />
                <Box
                    float="right"
                    minHeight="100vh"
                    height="100%"
                    overflow="auto"
                    position="relative"
                    maxHeight="100%"
                    w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                    maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease"
                >
                    <Portal>
                        <Box>
                            <Navbar
                                onOpen={onOpen}
                                logoText={'UCSC Career Fair 2025'}
                                brandText={getActiveRoute(routes)}
                                secondary={getActiveNavbar(routes)}
                                message={getActiveNavbarText(routes)}
                                fixed={fixed}
                                {...rest}
                            />
                        </Box>
                    </Portal>

                    {getRoute() ? (
                        <Box mx="auto" p={{ base: '40px', md: '50px' }} pb={0} pe="20px" minH="100vh" pt="50px">
                            <Switch>
                                {getRoutes(routes)}
                                <Redirect from="/" to="/admin/all-companies" />
                            </Switch>
                        </Box>
                    ) : null}
                </Box>
            </SidebarContext.Provider>
        </Box>
    );
}
