import axios from 'axios';
import { baseUrl } from 'constant/baseUrl';

export class FileApi {
    uploadFile(payload: FormData) {
        return axios.post(`${baseUrl}/file/upload`, payload);
    }
}
const fileApiInstance = new FileApi();
export default fileApiInstance;
