import { DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    HStack,
    Skeleton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ViewAdvertModel } from 'components/view-advert/ViewAdvertModel';
import { QueryKeys } from 'constant/queryKeys';
import { AppAlert } from 'contexts/AppAlert';
import { useAuth } from 'contexts/AuthContext';
import profileApi from 'core/api/profile.api';
import vacancyApi from 'core/api/vacancy.api';
import { Company } from 'core/models/company.model';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import ComingSoon from './../../coming-soon';
import { VacancyForm } from './VacancyForm';

export function CompanyVacancyPage() {
    const toast = useToast();
    const auth = useAuth();

    const {
        data: company,
        isLoading,
        refetch,
    } = useQuery<Company>({
        queryKey: [QueryKeys.COMPANY_PROFILE],
        queryFn: () => profileApi.getCompanyProfile().then((res) => res.data),
    });

    const handleVacancyDelete = async (vacancyId: string) => {
        AppAlert.fire({
            title: 'Are you sure?',
            text: "You will not be able to recover this vacancy and it's applicants!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            confirmButtonColor: 'red',
        }).then((result) => {
            if (result.isConfirmed) {
                vacancyApi
                    .deleteVacancy(vacancyId)
                    .then((res) => {
                        toast({
                            title: 'Vacancy deleted successfully',
                            status: 'success',
                        });
                        refetch();
                    })
                    .catch((err) => {
                        toast({
                            title: 'Error deleting the vacancy',
                            status: 'error',
                        });
                    });
            }
        });
    };

    // show the coming soon if vacancy is not updatable and there are no vacancies
    if (company?.vacancyUpdatable === false && company?.vacancies?.length === 0) {
        return <ComingSoon></ComingSoon>;
    }

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <Flex p={6} pt={0} justifyContent="space-between" alignItems="center">
                    <Text>
                        Posted Vacancies : <b>{auth.company?.name}</b>
                    </Text>
                    <VacancyForm vacancy={null} />
                </Flex>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>Job Title</Th>
                                <Th>Advert</Th>
                                <Th>Applicants</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {!!!company?.vacancies?.length && (
                                <Tr>
                                    <Td colSpan={4} textAlign="center">
                                        <Skeleton isLoaded={!isLoading}>
                                            <Alert status="warning" borderRadius="5px" justifyContent="center">
                                                <AlertIcon />
                                                <AlertDescription>No Posted Vacancies.</AlertDescription>
                                            </Alert>
                                        </Skeleton>
                                    </Td>
                                </Tr>
                            )}
                            {company?.vacancies?.map((vacancy, index) => (
                                <Tr key={vacancy._id}>
                                    <Td>{index + 1}</Td>
                                    <Td style={{ whiteSpace: 'break-spaces' }}>{vacancy.jobTitle}</Td>
                                    <Td>
                                        <ViewAdvertModel vacancy={vacancy} />
                                    </Td>
                                    <Td>
                                        <RouterLink to={'/company/vacancies/' + vacancy._id}>
                                            <Button
                                                colorScheme="blue"
                                                variant="outline"
                                                size="sm"
                                                isDisabled={company.cvVisible === false}
                                            >
                                                <ViewIcon />
                                                &nbsp; View Applicants (
                                                {company.cvVisible ? vacancy.applications?.length : 0})
                                            </Button>
                                        </RouterLink>
                                    </Td>
                                    <Td align="right">
                                        <HStack spacing={2} justifyContent="end">
                                            <VacancyForm vacancy={vacancy} />
                                            <Button
                                                colorScheme="red"
                                                variant="outline"
                                                size="sm"
                                                isDisabled={
                                                    company?.vacancyUpdatable === false ||
                                                    !!vacancy.applications?.length
                                                }
                                                onClick={() => handleVacancyDelete(vacancy._id)}
                                            >
                                                <DeleteIcon /> &nbsp; Delete
                                            </Button>
                                        </HStack>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
}
