import { useAuth } from '../contexts/AuthContext';
import { useEffect, useState } from 'react';
import companyApi from '../core/api/company.api';
import { useLocation } from 'react-router-dom';
import { Company } from '../core/models/company.model';

export const isCompanyDetailsFilled = (data: Company) => {
    return data.name && data.address && data.contactPerson && data.contactPerson && data.contactNo;
};

/**
 *
 * Hook for getting allowed routes for a company based on its profile completion and payment status
 *
 * **/
const useCompanyRoutes = (routes: RoutesType[]) => {
    const auth = useAuth();
    const location = useLocation();
    const [finalRoutes, setFinalRoutes] = useState(routes);
    const [isUserInRestrictedRoute, setIsUserInRestrictedRoute] = useState(false);

    useEffect(() => {
        async function getCompanyData() {
            try {
                const res = await companyApi.getCompany(auth.user.companyId);
                const data = res.data;

                let finalRoutesTemp = routes.map((route) => {
                    if (route.path !== '/profile' && route.path !== '/sponsorships') {
                        return {
                            ...route,
                            isLocked: true,
                        };
                    } else {
                        return route;
                    }
                });

                // Only allow /stalls if company has filled its details
                if (isCompanyDetailsFilled(data)) {
                    finalRoutesTemp = finalRoutesTemp.map((route) => {
                        if (route.path === '/stalls') {
                            return {
                                ...route,
                                isLocked: false,
                            };
                        } else {
                            return route;
                        }
                    });
                }

                // Allow everything if company has paid
                if (isCompanyDetailsFilled(data) && data.isPaid) {
                    finalRoutesTemp = finalRoutesTemp.map((route) => {
                        return {
                            ...route,
                            isLocked: false,
                        };
                    });
                }
                setFinalRoutes(finalRoutesTemp);

                //     Check whether user is currently in a restricted route

                const currentPath = '/' + location.pathname.split('/').reverse()[0];
                finalRoutesTemp.forEach((route) => {
                    if (route.path === currentPath && route.isLocked) setIsUserInRestrictedRoute(true);
                });
            } catch (e) {
                console.log(e);
            }
        }

        if (auth.isLoggedIn && auth.user.role === 'company') getCompanyData();
    }, [auth, location]);

    return { finalRoutes, isUserInRestrictedRoute };
};

export default useCompanyRoutes;
