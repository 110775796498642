import { Route, Switch } from 'react-router-dom';
import VacancyProfile from './components/VacancyProfile';

import { CompanyVacancyPage } from './components/CompanyVacancyPage';

export default function Test() {
    return (
        <Switch>
            <Route path={'/company/vacancies/:vacancyId'} component={VacancyProfile} key={'vacancy-profile'} />
            <Route path={'/company/vacancies'} component={CompanyVacancyPage} key={'vacancy-profile'} />
        </Switch>
    );
}
