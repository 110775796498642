import { useEffect, useState } from 'react';
import { Avatar, Box, Button, HStack, IconButton, Spinner, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import fileApi from 'core/api/file.api';
import { CompanyUpdateRequest } from 'core/models/company.model';

function UserProfile(props: {
    image: string;
    companyName: string;
    updateLogoUrl: (data: CompanyUpdateRequest) => void;
    setAlertMessage: (message: any) => void;
    [x: string]: any;
}) {
    const { image, companyName, updateLogoUrl, ...rest } = { ...props };
    const { onOpen, onClose } = useDisclosure();
    const [isLoading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [selectedFile, setSelectedFile] = useState({ name: null, file: null });
    const [previewUrl, setPreviewUrl] = useState(null);
    const toast = useToast();

    const handleEditClick = () => {
        onOpen();
        setIsEditing(true);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            var payload = new FormData();
            payload.append('file', selectedFile.file);
            const res = await fileApi.uploadFile(payload);
            if (!res.data.url) throw Error;
            await updateLogoUrl({ logoURL: res.data.url });
        } catch (error) {
            console.log(error);
            toast({
                title: 'Upload Failed',
                description: "We couldn't upload the file. Please try again",
                status: 'error',
                duration: 8000,
                isClosable: true,
            });
        }
        setPreviewUrl(null);
        onClose();
        setIsEditing(false);
        setLoading(false);
        props.setAlertMessage(
            <>
                Please click <strong>Update</strong> to save changes.
            </>
        );
    };

    const handleCancel = () => {
        setPreviewUrl(null);
        setIsEditing(false);
        onClose();
    };

    const handleImageChange = (e: any) => {
        console.log(e);
        const file = e.target.files[0];
        e.preventDefault();
        setSelectedFile({ name: 'logo', file: file });

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (isEditing) document.getElementById('fileInput').click();
    }, [isEditing]);

    return (
        <>
            <Box position="relative" display="inline-block" {...rest}>
                <Tooltip label="Edit Logo" hasArrow>
                    <Avatar
                        style={{ overflow: 'hidden' }}
                        size="2xl"
                        bg="white"
                        border={1}
                        name={companyName}
                        src={previewUrl ?? image}
                        _hover={{ cursor: 'pointer' }}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        onClick={() => {
                            setIsEditing(true);
                            if (isEditing) document.getElementById('fileInput').click();
                        }}
                    >
                        <Box
                            position="absolute"
                            inset={0}
                            bg="rgba(0,0,0,0.5)"
                            opacity={isEditing || isHovering ? 1 : 0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            transition="opacity 0.3s"
                            borderRadius="50%"
                        >
                            {isLoading ? (
                                <Spinner color={'white'} />
                            ) : (
                                <IconButton
                                    icon={<MdEdit />}
                                    colorScheme="white"
                                    onClick={handleEditClick}
                                    aria-label="Edit Image"
                                    size="xl"
                                />
                            )}
                        </Box>
                    </Avatar>
                </Tooltip>
            </Box>

            <Box mt={4}>
                {isEditing && (
                    <>
                        <input
                            type="file"
                            id="fileInput"
                            accept=".jpg, .jpeg, .png"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                        <HStack spacing={4} mb={4}>
                            <Button onClick={handleSave} variant="brand">
                                Upload
                            </Button>
                            <Button variant="action" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </HStack>
                    </>
                )}
            </Box>
        </>
    );
}

export default UserProfile;
